import email_id from '../../Assets/Images/con_mail_id.png';
import contact_img from '../../Assets/Images/contact_per_img.png';
import './contactUs.css';
import hih_logo from '../../Assets/Images/logo/hih_logo.png';
import svr_logo from '../../Assets/Images/logo/svr_logo.png';
import meithee_logo from '../../Assets/Images/logo/meithee_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function ContactUS() {
    const recipientEmail = "support@hireinhurry.com";
    const mailtoUrl = `mailto:${recipientEmail}`;
    return (
        <section id="contact_us">
            <div className="container">
                <div className="row">
                    <div className='col-md-2'>
                        <img className='contact-left-img' src={contact_img} alt='Contact person'/>
                    </div>
                    <div className="col-md-10">
                        <div className="start-box">
                            <h3>Contact Us</h3>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <p><i className="fa-solid fa-location-dot"></i>41, Wylie Cir Georgetown ON L7G 5L9,<br /> <span className='addr_cont'>Canada.</span></p>
                                </div>
                                <div className='col-md-5 cont-right'>
                                    <li><FontAwesomeIcon icon={faEnvelope} /> <a href={mailtoUrl}><img className='email_id_img' src={email_id} alt='email_id'/></a></li>
                                    <li><FontAwesomeIcon icon={faPhone} /> <span>+1 647-846-3165</span></li>
                                </div>
                            </div>
                            <h3>Our Partners</h3>
                            <div className='row'>
                                <div className='col-md-4 our-partner'>
                                    <img className='contact_logo dw_l' src={hih_logo} alt="dw_logo"/>
                                </div>
                                <div className='col-md-4 our-partner'>
                                    <img className='contact_logo svr_l' src={svr_logo} alt="svr_logo"/>
                                </div>
                                <div className='col-md-4 our-partner'>
                                    <img className='contact_logo meit_l' src={meithee_logo} alt="meithee_logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUS;