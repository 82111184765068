import Sidebar from "../../Components/Util/Sidebar/Sidebar";
import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import './WebConfig.css';
import { ORG_DATA, TOKEN } from "../../Components/Util/Constant";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const WebConfig = () => {
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const web_data = useParams();
    const BASE_URL_APPSER = process.env.REACT_APP_API_BASE_URL_APPSER;
    const [configFormJson, setConfigFormJson] = useState([]);
    const [groupingFormJson, setGroupingFormJson] = useState([]);
    const [groupingKeys, setGroupingKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    // const configFormJson = [
    //     {
    //         "field_name": "DB Host",
    //         "field_type": "text",
    //         "field_key": "db_host",
    //         "field_description": "Stores IP address / domain namne of Database server",
    //         "required": true,
    //         "default": "localhost",
    //         "group": "Environment",
    //     },
    //     {
    //         "field_name": "DB Port",
    //         "field_type": "text",
    //         "field_key": "db_port",
    //         "field_description": "Stores port number of Database server",
    //         "required": false,
    //         "value": "root",
    //         "default": 3306,
    //         "group": "Database",
    //     },
    //     {
    //         "field_name": "DB Name",
    //         "field_type": "text",
    //         "field_key": "db_name",
    //         "field_description": "Stores name of Database that Application use",
    //         "required": true,
    //         "default": "master",
    //         "group": "Database",
    //     },
    //     {
    //         "field_name": "DB Username sha",
    //         "field_type": "autocomplete",
    //         "field_key": "db_user1",
    //         "field_description": "Stores name of Database username that Application use",
    //         "required": true,
    //         "default": null,
    //         "options": ["root", "admin"],
    //         "group": "Database",
    //     },
    //     {
    //         "field_name": "DB Username",
    //         "field_type": "dropdown",
    //         "field_key": "db_user",
    //         "field_description": "Stores name of Database that Application use",
    //         "required": true,
    //         "default": "root",
    //         "options": ["root", "admin"],
    //         "group": "Database",
    //     },
    //     {
    //         "field_name": "DB Password",
    //         "field_type": "password",
    //         "field_key": "db_password",
    //         "field_description": "Stores password of Database that Application use",
    //         "required": true,
    //         "default": null,
    //         "group": "Database",
    //     }
    // ];

    const getConfigByAppId = async (web_id) => {
        let token = localStorage.getItem(TOKEN);
        let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
        if (token) {
          setLoading(true);
          await axios
            .get(`${BASE_URL_APPSER}/apps/${web_id}/env/user`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                OrgId: org_data?.orgId
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
            if (data.data?.length) {
                setConfigFormJson(data.data);
                let obj = groupedFields(data.data);
                setGroupingFormJson(obj);
                const keys = Object.keys(obj);
                setGroupingKeys(keys);
            } else {
                setTimeout(() => {
                    navigate(-1);
                }, 1000);
            }
            setLoading(false);
          })
          .catch((err) => {
              console.error(err);
              if (err?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
              }
              setLoading(false);
          }).finally(() => {
            setLoading(false);
          });
        }
    }

    let validationSchema;

    if (Array.isArray(configFormJson)) {
        validationSchema = yup.object().shape(
            configFormJson.reduce((schema, field) => {
            const { field_key, field_type, required } = field;

            let fieldValidation = yup.string();

            if (required) {
                fieldValidation = fieldValidation.required(`${field.field_name} is required`);
            }

            return {
                ...schema,
                [field_key]: fieldValidation,
            };
            }, {})
        );
    } else {
        // Handle the case where configFormJson is not an array
        validationSchema = yup.object(); // A basic schema without any validations
    }

    const { handleSubmit, register, setValue, watch, reset, formState: { errors }, clearErrors } = useForm({
        resolver: async (data) => {
          try {
            await validationSchema.validate(data, { abortEarly: false });
            return {
              values: data,
              errors: {},
            };
          } catch (validationErrors) {
            return {
              values: {},
              errors: validationErrors.inner.reduce((errorsObj, error) => {
                if (error.path) {
                  errorsObj[error.path] = error.message;
                }
                return errorsObj;
              }, {}),
            };
          }
        },
    });

    const groupedFields = (dat) => {
        const groupedFields = dat.reduce((groups, field) => {
          const groupName = field.group_name;
          if (!groups[groupName]) {
            groups[groupName] = [];
          }
          groups[groupName].push(field);
          return groups;
        }, {});
        return groupedFields;
    }

    const renderFormFields = useCallback((paramgrp) => {
      return groupingFormJson[paramgrp]?.map((field) => {
        const { field_key, field_type, field_name, options, default: defaultValue, required } = field;
        let fieldComponent = null;
        let field_value = defaultValue;
        if (field?.field_value) {
          field_value = field?.field_value;
        }
    
        switch (field_type) {
          case 'text':
          case 'password':
            fieldComponent = (
              <input
                type={field_type}
                name={field_key}
                value={watch(field_key) || field_value || ''}
                {...register(field_key)}
                onChange={(e) => {setValue(field_key, e.target.value);clearErrors(field_key);}}
              />
            );
            break;
          case 'autocomplete':
            fieldComponent = (
              <Typeahead
                id={`${field_key}-typeahead`}
                name={field_key}
                options={options}
                selected={watch(field_key) ? [watch(field_key)] : []}
                {...register(field_key)}
                onChange={(selectedOptions) => {
                  const selectedValue = selectedOptions.length > 0 ? selectedOptions[0] : '';
                  setValue(field_key, selectedValue);
                  clearErrors(field_key);
                }}
              />
            );
            break;
          case 'dropdown':
            fieldComponent = (
              <select
                name={field_key}
                className='website-dropdown'
                value={watch(field_key) || field_value || ''}
                {...register(field_key)}
                onChange={(e) => {setValue(field_key, e.target.value);clearErrors(field_key);}}
              >
                <option value="">Select an option</option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            );
            break;
          default:
            break;
        }
    
        return (
          <div className="col-md-6" key={field_key}>
            <div className="form-field">
              <label className="form-label" htmlFor={field_key}>
                {field_name} {required ? <span className='mand-star'>*</span> : ''}
              </label>
              {fieldComponent}
            </div>
            {errors[field_key] && <span className="error-msg">{errors[field_key]}</span>}
          </div>
        );
      });
    }, [groupingFormJson]); 
    
      
    // const renderFormFields = (paramgrp) => {
    //     return groupingFormJson[paramgrp]?.map((field) => {
    //       const { field_key, field_type, field_name, group_name, options, default: defaultValue, required } = field;
    //       let fieldComponent = null;
    //       let field_value = defaultValue;
    //       if (field?.field_value) {
    //         field_value = field?.field_value;
    //       }
    
    //       switch (field_type) {
    //         case 'text':
    //         case 'password':
    //           fieldComponent = (
    //             <input
    //               type={field_type}
    //               name={field_key}
    //               value={watch(field_key) || field_value || ''}
    //               {...register(field_key)}
    //               onChange={(e) => {setValue(field_key, e.target.value);clearErrors(field_key);}}
    //             />
    //           );
    //           break;
    //         case 'autocomplete':
    //           fieldComponent = (
    //             <Typeahead
    //                 id={`${field_key}-typeahead`}
    //                 name={field_key}
    //                 options={options}
    //                 selected={watch(field_key) ? [watch(field_key)] : []}
    //                 {...register(field_key)}
    //                 onChange={(selectedOptions) => {
    //                     const selectedValue = selectedOptions.length > 0 ? selectedOptions[0] : '';
    //                     setValue(field_key, selectedValue);
    //                     clearErrors(field_key);
    //                 }}
    //                 />
    //           );
    //           break;
    //         case 'dropdown':
    //           fieldComponent = (
    //             <select
    //               name={field_key}
    //               className='website-dropdown'
    //               value={watch(field_key) || field_value || ''}
    //               {...register(field_key)}
    //               onChange={(e) => {setValue(field_key, e.target.value);clearErrors(field_key);}}
    //             >
    //               <option value="">Select an option</option>
    //               {options.map((option) => (
    //                 <option key={option} value={option}>
    //                   {option}
    //                 </option>
    //               ))}
    //             </select>
    //           );
    //           break;
    //         default:
    //           break;
    //         }
            
    //         return (
    //             <div className="col-md-6" key={field_key}>
    //                 <div className="form-field">
    //                     <label className="form-label" htmlFor={field_key}>
    //                         {field_name} {required? <span className='mand-star'>*</span> : ''}
    //                     </label>
    //                     {fieldComponent}
    //                 </div>
    //                 {errors[field_key] && <span className="error-msg">{errors[field_key]}</span>}
    //             </div>
    //         );
    //     });
    // };

    const handleFormSubmit = async (data) => {
        try {
            setErrorMsg('');
            let token = localStorage.getItem(TOKEN);
            let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
            if (data) {
                const payload = {
                    data: data
                }
                axios.post(`${BASE_URL_APPSER}/apps/${web_data.web_id}/env/submit`, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                        OrgId: org_data?.orgId
                    }
                    })
                    .then((response) => {
                        setSuccessMsg('Configuration updated succesfully');
                        reset();
                        setTimeout(() => {
                            navigate(`/${web_data.app_id}/website/list`);
                            setSuccessMsg('');
                        }, 1500);
                    })
                    .catch((error) => {
                        if (error.response) {
                            const errorMessage = error.response.data.msg || 'Creation failed';
                            setErrorMsg(errorMessage);
                        } else {
                        setErrorMsg('An error occurred while registering');
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getConfigByAppId(web_data.web_id);
    }, [web_data]);

    return (
        <>
            <div className="dashboard-container web-config-section">
                <Sidebar />
                <div className='layout-sec'>
                    <Container id="web_config_section" className="web-config-section mt-30">
                        <div className="head-sec">
                            <h3 className="inner-page-title">Website Configuration</h3>
                        </div>
                        <Row className="web-config-content-sec">
                            <Col className="m-auto" lg={10}>
                                <form className="create-form" onSubmit={handleSubmit(handleFormSubmit)}>
                                        {groupingKeys && groupingKeys.map((key, i) => {
                                            return (
                                                <div className="row" key={key}>
                                                    <span className={i > 0 ? 'group-title brdr_top' : 'group-title'}>{key}</span>
                                                    {renderFormFields(key)}
                                                </div>
                                            );
                                        })}
                                    {errorMsg && <div className="error-msg">{errorMsg}</div>}
                                    <div className="form-submit-btn d-flex">
                                        {successMsg != '' &&
                                            <div className="success-msg">{successMsg}</div>
                                        }
                                        {configFormJson.length > 0 &&
                                            <button type="submit" className="submit_btn">Submit</button>
                                        }
                                    </div>
                                </form>
                                {!loading && configFormJson.length == 0 &&
                                    <span className="not-found">No data found</span>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default WebConfig;