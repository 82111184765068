import './Product.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { APP_ID, ORG_DATA, SAVE_FILER_CAT, TOKEN } from '../../Components/Util/Constant';
import Sidebar from '../../Components/Util/Sidebar/Sidebar';
import { Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductList = () => {
    const [ProductList, setProductList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [websiteList, setWebsiteList] = useState([]);
    const BASE_URL_WSADMPR = process.env.REACT_APP_API_BASE_URL_WSADMPR;
    const filter_data = localStorage.getItem(SAVE_FILER_CAT);

    const fetchProductList = async (web_key) => {
        let token = localStorage.getItem(TOKEN);
        if (token) {
          await axios
            .get(`${BASE_URL_WSADMPR}/admin/product/list`, {
              headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
                'Api-Key': web_key,
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
              setProductList(data.data);
          })
          .catch((err) => {
                setProductList([]);
                console.log(err);
          });
        }  
    };

    const fetchWebsiteList = async () => {
        let token = localStorage.getItem(TOKEN);
        let app_id = localStorage.getItem(APP_ID);
        let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
        if (org_data?.orgId && app_id && token) {
          await axios
            .get(`${BASE_URL}/appser/api/apps/`+ app_id +`/configs`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                OrgId: org_data?.orgId
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
              setWebsiteList(data.data);
              setSelectedOption(data.data[0].id);
              if (filter_data) {
                let wdt = JSON.parse(filter_data);
                setSelectedOption(wdt?.web_id);
              }
          })
          .catch((err) => {
              console.log(err);
          });
        }
    };

    const onChangeWebsite = (e) => {
        let web_id = e.target?.value;
        if (web_id) {
            fetchProductList(web_id);
            setSelectedOption(web_id);
        }
    }

    const formatName = (name) => {
        return name.replace(/\s+/g, '-').toLowerCase(); // Replace multiple spaces with a single hyphen
    }

    useEffect(() => {
        fetchProductList(selectedOption);
        if (selectedOption != '') {
            let wb_id = formatName(selectedOption);
            let wb_dt = {"web_id": wb_id}
            localStorage.setItem(SAVE_FILER_CAT, JSON.stringify(wb_dt));
        }
    }, [selectedOption])

    useEffect(() => {
        fetchWebsiteList();
    }, [])
  
    return (
        <>
            <div className="dashboard-container">
                <Sidebar />
                <div className='layout-sec'>
                <Container id="prod_list" className="prod-list mt-30">
                    <div className="head-sec">
                        <h3 className="inner-page-title">Products</h3>
                        <Link className="add-btn" to={"/product/add"}>Add Product</Link>
                    </div>
                    <div className='col-6 m-auto'>
                        <label htmlFor="web_list">Websites</label>
                        <select id='web_list' name='web_list' className='website-dropdown' value={selectedOption || ''} onChange={onChangeWebsite}>
                            {websiteList.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option?.data?.name}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="prod-list-table">
                        <Table striped className="table-number">
                            <thead className="table-head">
                                <tr>
                                    <th className="table-col-name">Name</th>
                                    <th className="table-col-price">Price </th>
                                    <th className="table-col-image">Page URL</th>
                                </tr>
                            </thead>
                        </Table>
                        <div className="table-body-container">
                            <Table striped className="table-number">
                                <tbody>
                                    {ProductList?.length > 0 && ProductList?.map((proData, indx) => {
                                        return (
                                            <tr className="table-row" key={proData?.id}>
                                                <td className="table-col-name"><Link to={`/${selectedOption}/product/edit/${proData?.id}`}>{proData?.name}</Link></td>
                                                <td className="table-col-price">{proData?.price}</td>
                                                <td className="table-col-image">{proData?.page_url}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        {!ProductList?.length > 0  && <p className="no-data">No products found</p>}
                    </div>
                </Container>
                </div>
            </div>
        </>
    )
}

export default ProductList;