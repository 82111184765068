import { Container, Table } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import './Organization.css';
import { Link, useNavigate } from "react-router-dom";
import { ORG_DATA } from "../../Components/Util/Constant";

const OrganizationList = () => {
    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [orgList, setOrgList] = useState({});

    const fetchOrgList = async () => {
        let token = localStorage.getItem("token");
        await axios
          .get(`${BASE_URL}/orgser/api/org/list`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .then((data) => {
            // contextApi.userItem = data.data;
            setOrgList(data.data);
        })
        .catch((err) => {
            console.error(err);
            if (err?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        });
    };

    const navigateToQa = (orgId, OrgName) => {
        const myOrg = {
            orgId: orgId,
            name: OrgName
        };
        localStorage.setItem(ORG_DATA, JSON.stringify(myOrg));
        navigate('/qa/index');
    }

    useEffect(() => {
        fetchOrgList();
    }, [])

    return (
        <>
            <Container id="org_list" className="org-list mt-30">
                <div className="head-sec">
                    <h3 className="inner-page-title">Organization</h3>
                    <Link className="add-btn" to="/organization/create">Add Organization</Link>
                </div>
                <div className="org-list-table">
                <Table striped className="table-number">
                        <thead className="table-head">
                            <tr>
                                <th className="table-col-name">Name</th>
                                <th className="table-col-website">Website</th>
                                <th className="table-col-address">Address</th>
                                <th className="table-col-role">Role in Organization</th>
                                <th className="table-col-enr-date">Enrolled Date</th>
                                <th className="table-col-end-date">End Date</th>
                                {/* <th>Action</th> */}
                            </tr>
                        </thead>
                    </Table>
                    <div className="table-body-container">
                        <Table striped className="table-number">
                            <tbody>
                                {orgList?.length > 0 && orgList?.map((orgData, indx) => {
                                    return (
                                        <tr className="table-row" onClick={(e) => navigateToQa(orgData?.id, orgData.name)} key={orgData?.id}>
                                            <td className="table-col-name"><Link onClick={(e) => navigateToQa(orgData?.id, orgData.name)}>{orgData.name}</Link></td>
                                            <td className="table-col-website">{orgData.website}</td>
                                            <td className="table-col-address">{orgData.address}</td>
                                            <td className="table-col-role">OSA</td>
                                            <td className="table-col-enr-date">-</td>
                                            <td className="table-col-end-date">-</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    {!orgList?.length > 0  && <p className="no-data">No organization found</p>}
                </div>
            </Container>
        </>
    )
}

export default OrganizationList;