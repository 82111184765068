import React from 'react';

const Pagination = ({ paginationData, onPageChange }) => {
  const { current_page, last_page, links } = paginationData;
    
  const handlePageChange = (url) => {
    const pageNumber = url.split('=').pop();
    onPageChange(pageNumber);
  };

  return (
    <nav className='pagination-nav'>
      <ul className="pagination">
        {links?.map((link, index) => (
          <li key={index} className={`page-item ${link.active ? 'active' : ''}`}>
            {link.url ? (
              <button className="page-link" dangerouslySetInnerHTML={{ __html: link.label }} onClick={() => handlePageChange(link.url)}>
              </button>
            ) : (
                <>
                    {current_page > 1 &&
                        (<span className="page-link" dangerouslySetInnerHTML={{ __html: link.label }}></span>)
                    }
                </>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
