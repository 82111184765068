import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { MuiChipsInput } from 'mui-chips-input';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './applyJob.css';
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

const ApplyJob = () => {
    const BASE_URL = process.env.REACT_APP_CAREER_API_URL;
    const paramsDt = useParams();
    const [applyFormData, setApplyFormData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [chips, setChips] = React.useState({});
    const [formData, setFormData] = useState(new FormData());
    // const allowedFileTypes1 = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/*'];
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    useEffect(() => {
        getApplyFormData(paramsDt.ado_id);
    }, [paramsDt.ado_id]);

    const handleFileChange = (e, field_key, allowedFileTypes) => {
        const file = e.target.files[0];
    
        if (file && allowedFileTypes.includes(file.type)) {
          // Append the selected file to the FormData
            setFiles(prevFiles => [
                ...prevFiles,
                {
                    // id: Date.now(), // Unique identifier for the file
                    [field_key]: file,
                },
            ]);
          console.log('File added to FormData:', file);
          document.getElementById('err_'+field_key).innerText = '';
          errors[field_key] = '';
        } else {
            setFiles([]);
            document.getElementById('err_'+field_key).innerText = 'Invalid file type. Please select a valid file.';
            // console.error('Invalid file type. Please select a valid file.');
        }
    };

    const getApplyFormData = async (jp_id) => {
        try {
            let response = await axios.get(BASE_URL + "job_application/create?jp_id="+jp_id);
            setApplyFormData(response.data?.data);
            // setApplyFormData(apidata.data);
        } catch (error) {
            console.error(error);
        }
    }

    let validationSchema;
    
    const { handleSubmit, register, setValue, watch, reset, formState: { errors }, clearErrors } = useForm({
        resolver: async (data) => {
          try {
            await validationSchema.validate(data, { abortEarly: false });
            return {
              values: data,
              errors: {},
            };
          } catch (validationErrors) {
            // console.log("validationErrors", validationErrors);
            return {
              values: {},
              errors: validationErrors.inner.reduce((errorsObj, error) => {
                if (error.path) {
                  errorsObj[error.path] = error.message;
                }
                return errorsObj;
              }, {}),
            };
          }
        },
    });

    if (Array.isArray(applyFormData.formData)) {
        let doc = document;
        validationSchema = yup.object().shape(
            applyFormData.formData.reduce((schema, field) => {
            const { field_label, field_key, field_type, is_mandatory } = field;

            let fieldValidation = yup.string();
            // console.log("applyFormData", applyFormData.formData);
            // if (is_mandatory) {
            if (is_mandatory && field_type.toUpperCase() != 'ATTACH') {
                fieldValidation = fieldValidation.required(`${field_label} is required`);
            }
            if (is_mandatory && field_type.toUpperCase() == 'ATTACH') {
                if (files.length > 0) {
                    if (doc.getElementById('err_'+field_key))
                        doc.getElementById('err_'+field_key).innerText = '';

                } else {
                    fieldValidation = fieldValidation.required(`${field_label} is required`);
                }
            }
            return {
                ...schema,
                [field_key]: fieldValidation,
            };
            }, {})
        );
    } else {
        // Handle the case where configFormJson is not an array
        validationSchema = yup.object(); // A basic schema without any validations
    }

    const handleChipsChanges = (field_key, chips) => {
        setFormData((prevFormData) => {
            const updatedFormData = new FormData(prevFormData);
            updatedFormData.set(field_key, chips.join(',')); // Assuming chips are strings
            return updatedFormData;
        });
    };

    const handleChipsChange11 = (field_key, newChips) => {
        console.log("field_key", field_key + "-----" + newChips);
        setChips((prevChips) => {
          const updatedChips = {
            ...prevChips,
            [field_key]: [...(prevChips[field_key] || []), ...newChips]
          };
          console.log("Updated chips:", updatedChips);
          return updatedChips;
        });
    };
    const handleChipsChange = (chip) => {
        setChips(chip);
    };

    const handleKeyDown = (e) => {
        if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
        }
    };

    const renderFormFields = useCallback((applyFormData) => {
        return applyFormData?.formData?.map((field) => {
            const { field_label, field_type, field_key, is_mandatory } = field;
            let fieldComponent = null;
    
            switch (field_type.toUpperCase()) {
                case 'TEXT-SL':
                    fieldComponent = (
                        <input
                            className="formbold-form-input"
                            type='text'
                            name={field_key}
                            value={watch(field_key) || ''}
                            {...register(field_key)}
                            onChange={(e) => { setValue(field_key, e.target.value); clearErrors(field_key); }}
                        />
                    );
                    break;
                case 'NUMBER':
                        fieldComponent = (
                            <input
                                className="formbold-form-input"
                                type='number'
                                name={field_key}
                                value={watch(field_key) || ''}
                                {...register(field_key)}
                                inputMode="numeric"
                                onKeyDown={handleKeyDown}
                                onChange={(e) => { setValue(field_key, e.target.value); clearErrors(field_key); }}
                                onInput={(e) => {
                                    const inputValue = e.target.value;
                                    const numericValue = parseFloat(inputValue);
                            
                                    if (!isNaN(numericValue) && numericValue >= 0) {
                                      setValue(field_key, numericValue);
                                      clearErrors(field_key);
                                    } else {
                                      // Handle invalid input (negative value)
                                      setValue(field_key, ''); // Clear the input value
                                    }
                                }}
                            />
                        );
                        break;
                case 'TEXT-ML':
                    fieldComponent = (
                        <textarea
                            type='text'
                            name={field_key}
                            value={watch(field_key) || ''}
                            {...register(field_key)}
                            onChange={(e) => { setValue(field_key, e.target.value); clearErrors(field_key); }}
                        ></textarea>
                    );
                    break;
                case 'SOS':
                    fieldComponent = (
                        <select
                            name={field_key}
                            className='formbold-form-input'
                            value={watch(field_key) || ''}
                            {...register(field_key)}
                            onChange={(e) => { setValue(field_key, e.target.value); clearErrors(field_key); }}
                        >
                            <option value="">Select an option</option>
                            {field?.field_options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    );
                    break;
                // case 'MOS':
                //     fieldComponent = (
                //         <MultiSelect
                //             options={options}
                //             value={selected}
                //             onChange={setSelected}
                //             labelledBy="Select"
                //         />
                //     );
                //     break;
                case 'TAGS': 
                    fieldComponent = (
                        <MuiChipsInput
                            value={chips[field_key] || []}
                            onChange={chips => handleChipsChange11(field_key, chips)}
                        />
                    );
                    break;
                case 'Y/N': 
                    fieldComponent = (
                        <label htmlFor="supportCheckbox" className="formbold-checkbox-label">
                            <div className="formbold-relative">
                                <input
                                    name={field_key}
                                    type="checkbox"
                                    id="supportCheckbox"
                                    className="formbold-input-checkbox"
                                    {...register(field_key)}
                                    onChange={(e) => { setValue(field_key, e.target.value); clearErrors(field_key); }}
                                />
                                <div className="formbold-checkbox-inner">
                                    <span className="formbold-opacity-0">
                                    <svg
                                        width="11"
                                        height="8"
                                        viewBox="0 0 11 8"
                                        fill="none"
                                        className="formbold-stroke-current"
                                        >
                                        <path
                                            d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                            strokeWidth="0.4"
                                            ></path>
                                    </svg>
                                    </span>
                                </div>
                            </div>
                            {/* {field_label} */}
                        </label>
                    );
                    break;
                case 'ATTACH': 
                    fieldComponent = (
                    <>
                        <input
                            type="file"
                            id={field_key}
                            name={field_key}
                            accept={field?.additional_data?.mime_types?.join(',')}
                            onChange={(e) => handleFileChange(e, field_key, field?.additional_data?.mime_types)}
                            // {...register(field_key)}
                        />
                        <p id={'err_'+field_key} className="error-msg top-0 mb-0"></p>
                    {/* {files.map(file => (
                        <div key={file.id}>
                            <p>Selected File: {file.file.name}</p>
                            <button onClick={() => handleRemoveFile(file.id)}>Remove</button>
                        </div>
                    ))} */}
                    </>
                );
                    break;
                default:
                    break;
            }
    
            return (
                <div className="col-lg-4 col-md-6 col-sm-12 pl-0" key={field_key}>
                    <div className="form-field">
                        <label className="formbold-form-label" htmlFor={field_key}>
                            {field_label} {is_mandatory ? <span className='mand-star'>*</span> : ''}
                        </label>
                        {fieldComponent}
                    </div>
                    {errors[field_key] && <span className="error-msg">{errors[field_key]}</span>}
                </div>
            );
        });
    }, [applyFormData, formData, watch, setValue, clearErrors, errors]);

    const jsonToFormData = (json) => {
        const formData = new FormData();
      
        Object.keys(json).forEach((key) => {
          if (Array.isArray(json[key])) {
            json[key].forEach((value) => {
                formData.append(key, value);
            });
          } else {
            formData.append(key, json[key]);
          }
        });
      
        return formData;
      }

    const handleFormSubmit = async (data) => {
        try {
            setIsLoading(true);
            setErrorMsg('');
            if (data) {
                data.jobPositionId = paramsDt.ado_id;
                data = jsonToFormData(data);
                if (files) {
                    const keys = Object.keys(files);
                    keys.map((item, index) => {
                        const kys = Object.keys(files[item])
                        data.append(kys[0], files[item][kys[0]]);
                    }); 
                }
                const payload = data;
                axios.post(`${BASE_URL}job_application`, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                    })
                    .then((response) => {
                        setSuccessMsg(response.data.msg);
                        reset();
                        setIsLoading(false);
                        setShowAlert(true);
                        // setTimeout(() => {
                        //     setSuccessMsg('');
                        // }, 1500);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (error.response) {
                            const errorMessage = error.response.data.msg || 'Creation failed';
                            setErrorMsg(errorMessage);
                            setShowErrorAlert(true);
                        } else {
                            setErrorMsg('An error occurred while registering');
                            setShowErrorAlert(true);
                        }
                });
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    // const onClickff = () => {
    //     if (files) {
    //         const keys = Object.keys(files);
    //         keys.map((item, index) => (
    //             console.log(item)
    //           ))  

    //     }
    // }
    const handleClose = () => {
        setShowAlert(false);
    }

    const handleErrorClose = () => {
        setShowErrorAlert(false);
    }

    return(
        <section className="apply-job-view container">
            {showAlert && 
                <Modal className="apply-popup" show={showAlert} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <p className="success_msg">{successMsg}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Link to={`/careers`} className="btn btn-success" onClick={handleClose}>
                            Ok
                        </Link>
                    </Modal.Footer>
                </Modal>}
            {showErrorAlert && 
                <Modal className="apply-popup" show={showErrorAlert} onHide={handleErrorClose} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <p className="error_msg">{errorMsg}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger" onClick={handleErrorClose}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>}
            <div className="row justify-content-center mt-3">
                <div className="col-lg-12">
                    <h3 className="mb-3"><a className="title-link" href={`/careers/${applyFormData?.jobPosting?.ado_id}`}>{applyFormData?.jobPosting?.title}</a> <span className="ado-id">#{applyFormData?.jobPosting?.ado_id}</span></h3>
                    <p className="card-text climb_1"> {applyFormData?.jobPosting?.location? (<span className="card-desc">Location:  {applyFormData?.jobPosting?.location}</span>): ''} {applyFormData?.jobPosting?.type && <span className="card-desc">Job Type: {applyFormData?.jobPosting?.type}</span> }</p>
                </div>
            </div>
            <div className="row formbold-main-wrapper">
                <div className="col-lg-12 p-0 formbold-form-wrapper">
                    {isLoading && (
                        <div className="popup-loader">
                        <FontAwesomeIcon icon={faSpinner} spin />  loading...
                        </div>
                    )}
                    <form className="create-form row" onSubmit={handleSubmit(handleFormSubmit)}>
                        {applyFormData?.formData && renderFormFields(applyFormData)}
                        <div className="form-submit-btn d-flex">
                            {applyFormData.formData &&
                                <button type="submit" className="formbold-btn">Submit</button>
                            }
                            {/* <button onClick={onClickff}>pdffff</button> */}
                        </div>
                    </form>
                    {/* <MuiChipsInput
                        value={chips || []}
                        onChange={handleChipsChange}
                    /> */}
                </div>
            </div>
        </section>
    )
}

export default ApplyJob;