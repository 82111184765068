import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { TOKEN } from "../Util/Constant";
import './Register.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationError, setRegistrationError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem(TOKEN);
    if (token) {
      // User is logged in
      setIsLoggedIn(true);
      // Redirect to the dashboard or appropriate page
      window.location.href = '/dashboard';
    }
  }, []);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
        .required('First name is required')
        .matches(/^[A-Za-z]/, 'First name should start with an alphabet'),
    last_name: Yup.string()
        .required('Last name is required')
        .matches(/^[A-Za-z]/, 'Last name should start with an alphabet'),
    email: Yup.string().required('Email is required')
        .matches(/^\S*$/, 'Email should not contain spaces')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid email address format')
        .email('Invalid email address'),
    password: Yup.string()
        .required('Password is required')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one symbol')
        .matches(/^\S*$/, 'Password should not contain spaces'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});


 
   const resolver = yupResolver(validationSchema);
   const formMethods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirmPassword: '',
      is_agreement_agreed: 1,
    },
    resolver,
  });
  
  const { handleSubmit, register, formState: { errors }, reset } = formMethods;
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleFormSubmit = async (values) => {
    try {
      // API request
      await axios.post(BASE_URL + '/memser/user/register', values, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Registration success
      setRegistrationSuccess(true);
      setRegistrationError('');
      reset();
    } catch (error) {
      if (error.response) {
        // Registration failed
        const errorMessage = error.response.data.msg || 'Registration failed';
        setRegistrationError(errorMessage);
      } else {
        setRegistrationError('An error occurred while registering');
      }
    }
  };

  useEffect(() => {
    let timeout;
    if (registrationSuccess) {
      timeout = setTimeout(() => {
        setRegistrationSuccess(false);
      }, 15000); // Hide the success message after 15 seconds
    }
    return () => clearTimeout(timeout);
  }, [registrationSuccess]);

  return (
    <>

      <Container id="register_sec" className="register-sec">
        <Row className="register-content-sec">
          <Col className="offset-lg-3" lg={6}>
            <div className="success-message">
              {registrationSuccess && (
                <div>
                  Your registration was successful. An email has been sent to your email address. Please click on the link in the email to verify your account and proceed with the login process.
                </div>
              )}
            </div>
            {registrationError && <div className="error-message" style={{textAlign : "center"}}>{registrationError}</div>}

            <form onSubmit={handleSubmit(handleFormSubmit)} className="register-form">
            <div className="row">
                    <div className="col-md-4">
                      <div className="form-field">
                        <label htmlFor="first_name">*First Name</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-field">
                        <input
                          type="text"
                          id="first_name"
                          {...register('first_name')}
                        />
                      </div>
                      {errors.first_name && <span className="error-msg">{errors.first_name.message}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-field">
                        <label htmlFor="last_name">*Last Name</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-field">
                        <input
                          type="text"
                          id="last_name"
                          {...register('last_name')}
                        />
                      </div>
                      {errors.last_name && <span className="error-msg">{errors.last_name.message}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-field">
                        <label htmlFor="email">*Email</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-field">
                        <input
                          type="text"
                          id="email"
                          {...register('email')}
                        />
                      </div>
                      {errors.email && <span className="error-msg">{errors.email.message}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-field">
                        <label htmlFor="password">*Password</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-field">
                        <div className="password-input-container">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            {...register('password')}
                          />
                          <div className="password-toggle-icon" onClick={toggleShowPassword}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                          </div>
                        </div>
                      </div>
                      {errors.password && <span className="error-msg">{errors.password.message}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-field">
                        <label htmlFor="confirmPassword">*Confirm Password</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="form-field">
                        <div className="password-input-container">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            {...register('confirmPassword')}
                          />
                          <div className="password-toggle-icon" onClick={toggleShowConfirmPassword}>
                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                          </div>
                        </div>
                      </div>
                      {errors.confirmPassword && <span className="error-msg">{errors.confirmPassword.message}</span>}
                    </div>
                  </div>
              <p className='login_link'>
                Already a DWP Member?
                <NavLink to="/login" className='login_btn'> Login</NavLink>
              </p>
              <div className="btn-div">
                <button type="submit" className="register_btn">Register</button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
