import { NavLink, Link, useLocation } from 'react-router-dom';
import dw_logo from '../../../Assets/Images/dw_logo.png';
import './Header.css';
import { useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import axios from 'axios';
import { TOKEN } from '../Constant';

const Header = () => {
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem(TOKEN));
    const [showMenu, setShowMenu] = useState(false);
    const location = useLocation();
    const isRegisterPage = location.pathname === '/register';
    const mobileMenuActive = () => {
        setShowMenu(value=>!value)
    }
    const handleLogout = () => {
        const confirmed = window.confirm('Are you sure you want to logout?');
        if (confirmed) {
        axios
          .post(BASE_URL + '/authser/logout', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(TOKEN)}`
            },
          })
          .then(() => {
            // Logout success
            localStorage.removeItem(TOKEN);
            // Redirect to the login page or appropriate page
            window.location.href = '/login';
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              // Invalid or expired token
              localStorage.removeItem(TOKEN);
              // Redirect to the login page or appropriate page
              window.location.href = '/login';
            } else {
              // Error occurred while making the request
              console.log('An error occurred:', error);
              // Handle error state or display error message
            }
          });
        }
    };
      
    return(
        <div className="header-sec">
            <div className="header-menu">
                <Row className='align-items-center'>
                    <Col xl={1} md={3} lg={2}>
                        <Link to="/"><img src={dw_logo} alt='dw_logo' className='img-fluid dw_logo' /></Link>
                    </Col>
                    <button className='mobile-menu' onClick={mobileMenuActive}><i className={showMenu ? "fa fa-times" : "fa fa-bars"}></i></button>
                    <Col xl={11} md={9} lg={10}>
                        <div className={`header-menus ${showMenu ? "showmenu" : ""}`}>
                            <Nav variant="pills" activeKey="1">
                            {isLoggedIn ? (
                                <>
                                    <Nav.Item>
                                        <NavLink to ="/Organization/list" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' } end>
                                            My Organizations
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to ="/" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' } title="Item">
                                            DW Profile
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to ="/" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' }>
                                            Add Apps
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to ="/" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' }>
                                            Help?
                                        </NavLink>
                                    </Nav.Item>
                                </>
                            ) : (
                                <>
                                    <Nav.Item>
                                        <NavLink to ="/careers" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' } end>
                                            Home
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to ="/careers" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' } title="Item">
                                            Careers
                                        </NavLink>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <NavLink to ="/" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' } title="Item">
                                            About Us
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to ="/" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' }>
                                            Services
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to ="/" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' }>
                                            Blogs
                                        </NavLink>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <NavLink to ="/contact-us" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' }>
                                            Contact Us
                                        </NavLink>
                                    </Nav.Item> 
                                </>
                            )}
                            {/* {isLoggedIn ? (
                                <button className='register_btn logout_btn' onClick={handleLogout}>Logout</button>
                                ) : isRegisterPage ? (
                                <NavLink to="/login" className='register_btn add-btn'>Login</NavLink>
                                ) : (
                                <NavLink to="/register" className='register_btn add-btn'>Register</NavLink>
                            )} */}
                            </Nav>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Header;