import './ProductCategory.css';
import Sidebar from '../../Components/Util/Sidebar/Sidebar';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState, useRef } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { APP_ID, ORG_DATA, SAVE_FILER_CAT, TOKEN } from '../../Components/Util/Constant';
import { useNavigate, useParams } from 'react-router';
import Editor from '../../Components/Util/Editor';

const Category = () => {
    const navigate = useNavigate();
    const [registrationError, setRegistrationError] = useState('');
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const BASE_URL_WSADMPR = process.env.REACT_APP_API_BASE_URL_WSADMPR;
    const [textItems, setTextItems] = useState([]);
    const [websiteList, setWebsiteList] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [selectedWebId, setSelectedWebId] = useState(null);
    const fileInputRef = useRef(null);
    const cat_data = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [editorHtml, setEditorHtml] = useState("");
    const [metaDescHtml, setMetaDescHtml] = useState("");
    const [editorLoaded, setEditorLoaded] = useState(false);
    const filter_data = localStorage.getItem(SAVE_FILER_CAT);

    const handleAddText = () => {
        let formData = watch();
        let inputText = formData?.seo_keywords;
        if (inputText?.trim() !== '') {
          setTextItems([...textItems, inputText]);
          setValue('seo_keywords', '');
        }
    };

    const handleRemoveText = (index) => {
        const updatedItems = textItems.filter((_, i) => i !== index);
        setTextItems(updatedItems);
    };

    const fetchWebsiteList = async () => {
        let token = localStorage.getItem(TOKEN);
        let app_id = localStorage.getItem(APP_ID);
        let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
        if (org_data?.orgId && app_id && token) {
          await axios
            .get(`${BASE_URL}/appser/api/apps/`+ app_id +`/configs`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                OrgId: org_data?.orgId
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
              setWebsiteList(data.data);
              if (cat_data.web_id) {
                  setValue('web_list', cat_data.web_id);
              }
              setTimeout(() => {
                  if (filter_data) {
                    let wdt = JSON.parse(filter_data);
                    setValue('web_list', wdt?.web_id);
                  }
              }, 100);
          })
          .catch((err) => {
              console.log(err);
          });
        }
    };

    useEffect(() => {
        setEditorLoaded(true);
        fetchWebsiteList();
    }, []);

    const getCategoryById = async (website_id, cat_id) => {
        let token = localStorage.getItem(TOKEN);
        if (token) {
          await axios
            .get(`${BASE_URL_WSADMPR}/admin/category/${cat_id}`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'Api-Key': website_id,
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
                let cat_data = data.data;
                setValue("name", cat_data.name);
                // setValue("description", cat_data.description);
                setValue("page_url", cat_data.page_url);
                setValue("seo_title", cat_data.seo_title);
                setEditorHtml(cat_data.description? cat_data.description : '');
                // setValue("meta_description", cat_data.seo_meta_description);
                setMetaDescHtml(cat_data.seo_meta_description? cat_data.seo_meta_description : '')
                setPreviewUrl(cat_data.seo_img);
                if (cat_data.seo_keywords) {
                    const keywordValues = Object.values(cat_data.seo_keywords);
                    for (const keyword of keywordValues) {
                        setTextItems((prevTextItems) => [...prevTextItems, keyword]);
                    }
                }
          })
          .catch((err) => {
              console.log(err);
          });
        }
    }

    useEffect(() => {
        if (cat_data.cat_id) {
            setIsEditMode(true);
            setValue('web_list', cat_data.web_id);
        }
        if (selectedWebId !== cat_data.web_id && cat_data.cat_id) {
            setSelectedWebId(cat_data.web_id);
            getCategoryById(cat_data.web_id, cat_data.cat_id);
        }
    }, [websiteList, cat_data]);

      // Define yup schema for form validation
    const schema = yup.object().shape({
        seo_img: isEditMode
        ? yup.mixed()
        : yup
            .mixed()
            .test('fileFormat', 'Invalid image format', (value) => {
              if (!value) {
                return true;
              }
              const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
              return !allowedTypes.includes(value.type) ? this.createError({ message: 'Invalid image format' }) : true;
            })
            .required('SEO Image is required'),
        name: yup.string().trim().required('Name is required')
        .matches(/^[A-Za-z]/, 'Name should start with an alphabet'),
        perma_link: yup.string().trim().required('Perma Link is required'),
        // page_url: yup.string().trim().url('Invalid Page URL')
        // .required('Page Url is required').trim()
        // .matches(/^[A-Za-z]/, 'Page Url should start with an alphabet'),
        seo_title: yup.string().trim().required('Seo Title is required')
        .matches(/^[A-Za-z]/, 'Seo Title should start with an alphabet'),
        web_list: yup.string().trim().required('Website is required'),
        
    });

    const { register, handleSubmit, watch, setValue, reset, formState: { errors }, trigger } = useForm({
        resolver: yupResolver(schema), // Use yupResolver here
    });

    const handleImageChange = async (event) => {
        const selectedFile = event.target.files[0];
        try {
            // const tempData = { seo_img: selectedFile };
            
            // const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            // if (!allowedTypes.includes(selectedFile.type)) {
            //     throw new Error('Invalid image type');
            // }
            if (selectedFile?.type.startsWith('image/')) {
                setValue('seo_img', selectedFile);
                await trigger('seo_img');
                setSelectedImage(selectedFile);
                const imageUrl = URL.createObjectURL(selectedFile);
                setPreviewUrl(imageUrl);
            } else {
                if (fileInputRef.current) {
                    fileInputRef.current.value = null; // Clear the file input
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeletePreview = async () => {
        setSelectedImage(null);
        setPreviewUrl(null);
        await trigger('seo_img');
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Clear the file input
        }
    };


    const handleFormSubmit = async (data) => {
        try {
            setRegistrationError('');
            const payload = {
                name: data.name,
                description: editorHtml,
                meta_description: metaDescHtml,
                // page_url: data.page_url,
                seo_img: '',
                perma_link: data.perma_link,
                seo_keywords: JSON.stringify(textItems),
                seo_title: data.seo_title,
            };
            if (selectedImage) {
                payload.seo_img = selectedImage;
            }
            let web_key = data.web_list;
            let token = localStorage.getItem(TOKEN);
            if (isEditMode) {
                axios.post(`${BASE_URL_WSADMPR}/admin/category/${cat_data.cat_id}/update`, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                        'Api-Key': web_key
                    }
                    })
                    .then((response) => {
                        setEditorHtml('');
                        setSuccessMsg('Category updated succesfully');
                        reset();
                        setTimeout(() => {
                            navigate('/product-category/list');
                            setSuccessMsg('');
                        }, 1500);
                    })
                    .catch((error) => {
                        if (error.response) {
                            const errorMessage = error.response.data.msg || 'Creation failed';
                            setRegistrationError(errorMessage);
                        } else {
                        setRegistrationError('An error occurred while registering');
                    }
                });
            } else {
            // API request
                axios.post(`${BASE_URL_WSADMPR}/admin/category/add`, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                        'Api-Key': web_key
                    }
                    })
                    .then((response) => {
                        setEditorHtml('');
                        setSuccessMsg('Category added succesfully');
                        reset();
                        setTimeout(() => {
                            navigate('/product-category/list');
                            setSuccessMsg('');
                        }, 1500);
                    })
                    .catch((error) => {
                        if (error.response) {
                            const errorMessage = error.response.data.msg || 'Creation failed';
                            setRegistrationError(errorMessage);
                        } else {
                        setRegistrationError('An error occurred while registering');
                    }
                });
            }
        } catch (error) {
          console.log("Error:", error);
        }
    };

    const handleKeyPress = (e) => {
        if (e.target.value) {
            // Remove any leading spaces from the first word
            e.target.value = e.target.value.replace(/^\s+/, '');
            
            // Replace multiple consecutive spaces with a single space
            e.target.value = e.target.value.replace(/\s{2,}/g, ' ');
        }
        trigger('name');
    };

    const formatName = (name) => {
        return name.replace(/\s+/g, '-').toLowerCase(); // Replace multiple spaces with a single hyphen
    }
    
    useEffect(() => {
        const nameValue = watch('name');
        let perma = formatName(nameValue);
        setValue("perma_link", perma);
    }, [watch('name')]);

    useEffect(() => {
        const nameValue = watch('web_list');
        if (nameValue != '') {
            let wb_id = formatName(nameValue);
            let wb_dt = {"web_id": wb_id}
            localStorage.setItem(SAVE_FILER_CAT, JSON.stringify(wb_dt));
        }
    }, [watch('web_list')]);

    return (
        <>
        <div className="dashboard-container prod-category-section">
            <Sidebar />
            <div className='layout-sec'>
                <Container id="prod_cat_section" className="prod-cat-section mt-30">
                    <div className="head-sec">
                        <h3 className="inner-page-title">Product Category</h3>
                    </div>
                    <Row className="prod-cat-content-sec">
                        <Col className="m-auto" lg={10}>
                            <form onSubmit={handleSubmit(handleFormSubmit)} className="create-form">
                                <div className='col-6 m-auto'>
                                    <label htmlFor="web_list">Websites<span className='mand-star'>*</span></label>
                                    <select id='web_list' name='web_list' className='website-dropdown' {...register('web_list')}>
                                        <option value=''>Select Website</option>
                                        {websiteList.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option?.data?.name}
                                        </option>
                                        ))}
                                    </select>
                                    {errors.web_list && <span className="error-msg">{errors.web_list.message}</span>}
                                </div>
                                <div className="row">
                                <div className="col-md-6">
                                        <div className="form-field">
                                            <label htmlFor="name">Name<span className='mand-star'>*</span></label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                {...register("name")}
                                                onKeyUp={handleKeyPress}
                                            />
                                        </div>
                                        {errors.name && <span className="error-msg">{errors.name.message}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-field">
                                            <label htmlFor="perma_link">Perma Link<span className='mand-star'>*</span></label>
                                            <input
                                                type="text"
                                                id="perma_link"
                                                name="perma_link"
                                                {...register("perma_link")}
                                                onKeyUp={handleKeyPress}
                                            />
                                        </div>
                                        {errors.perma_link && <span className="error-msg">{errors.perma_link.message}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className='col-md-12'>
                                            <div className="form-field">
                                                <label htmlFor="page_url">Page URL</label>
                                                <input
                                                    type="text"
                                                    id="page_url"
                                                    name="page_url"
                                                    {...register("page_url")}
                                                    onKeyUp={handleKeyPress}
                                                />
                                            </div>
                                            {errors.page_url && <span className="error-msg">{errors.page_url.message}</span>}
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="form-field">
                                                    <label htmlFor="seo_title">Seo Title<span className='mand-star'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="seo_title"
                                                        name="seo_title"
                                                        {...register("seo_title")}
                                                        onKeyUp={handleKeyPress}
                                                    />
                                                </div>
                                                {errors.seo_title && <span className="error-msg">{errors.seo_title.message}</span>}
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-field">
                                                    <label htmlFor="seo_keywords">Seo Keywords</label>
                                                    <div className='app-chip'>
                                                        <div className="chip-list">
                                                            {textItems.map((item, index) => (
                                                            <div key={index} className="chip">
                                                                {item}
                                                                <i className='fa fa-close chip-close' onClick={() => handleRemoveText(index)}></i>
                                                            </div>
                                                            ))}
                                                        </div>
                                                        <textarea
                                                            id="seo_keywords"
                                                            name="seo_keywords"
                                                            className="text-area"
                                                            rows={1}
                                                            placeholder="Enter keyword and press Enter"
                                                            {...register("seo_keywords")}
                                                            onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                handleAddText();
                                                            }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-field">
                                            <label htmlFor="seo_img">Meta Image URL<span className='mand-star'>*</span></label>
                                            <div className='d-flex'>
                                                <div className='img-preview'>
                                                    {previewUrl && (
                                                        <>
                                                            <i className='close-btn' onClick={handleDeletePreview}></i>
                                                            <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                                        </>
                                                    )}
                                                </div>
                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept=".jpg, .jpeg, .png"
                                                    onChange={handleImageChange}
                                                />
                                            </div>
                                        </div>
                                        {errors.seo_img && <span className="error-msg">{errors.seo_img.message}</span>}
                                    </div>
                                   
                                    <div className="col-md-6">
                                        <div className="form-field">
                                            <label htmlFor="description">Description</label>
                                            {/* <ReactQuill
                                                className='desc-quil'
                                                id="description"
                                                name="description"
                                                value={editorHtml}
                                                onChange={handleEditorChange}
                                                modules={modules}
                                            /> */}
                                            <Editor
                                                className='desc-quil'
                                                id="description"
                                                name="description"
                                                onChange={(data) => {
                                                    setEditorHtml(data);
                                                }}
                                                value={editorHtml}
                                                editorLoaded={editorLoaded}
                                            />
                                        </div>
                                        {errors.description && <span className="error-msg">{errors.description.message}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-field">
                                            <label htmlFor="meta_description">SEO Description</label>
                                            {/* <textarea
                                                type="text"
                                                id="meta_description"
                                                name="meta_description"
                                                {...register("meta_description")}
                                                onKeyUp={handleKeyPress}
                                            ></textarea> */}
                                            <Editor
                                                className='desc-quil'
                                                id="meta_description"
                                                name="meta_description"
                                                onChange={(data) => {
                                                    setMetaDescHtml(data);
                                                }}
                                                value={metaDescHtml}
                                                editorLoaded={editorLoaded}
                                            />
                                        </div>
                                        {errors.meta_description && <span className="error-msg">{errors.meta_description.message}</span>}
                                    </div>
                                    
                                </div>
                                {registrationError && <div className="error-msg">{registrationError}</div>}
                                <div className="form-submit-btn d-flex">
                                    {successMsg != '' &&
                                        <div className="success-msg">{successMsg}</div>
                                    }
                                    <button type="submit" className="submit_btn">{isEditMode? 'Update' : 'Submit'}</button>
                                </div>
                            </form>
                            {/* <LeaveConfirmationModal
                                isOpen={isConfirmationModalOpen}
                                onClose={() => setConfirmationModalOpen(false)}
                                onConfirm={handleLeaveConfirmation}
                            /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        </>
    );
}

export default Category;