import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import "./website.css";
import { ORG_DATA } from "../../Components/Util/Constant";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Website = () => {
    const navigate = useNavigate();
    const web_data = useParams();
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [loading, setLoading] = useState(false); // State for loading message
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationError, setRegistrationError] = useState("");

    const schema = yup.object().shape({
        website_name: yup.string()
            .required("Name is required")
            .matches(/^[A-Za-z]/, "Name should start with an alphabet"),
        website_url: yup.string()
            .required("Website is required")
            .matches(/^[A-Za-z]/, "Website should start with an alphabet")
            .matches(/\.[a-zA-Z]+$/, 'Invalid URL format'),
        ip_address: yup.string()
            .required("IP Address is required")
            .matches(/^[0-9]/, "IP Address should start with a number")
            .matches(
                /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/,
                "Invalid IP Address format"
            ),
    });

    const { handleSubmit, register, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        setLoading(true); // Show loading message
        setRegistrationSuccess(false); // Reset success message
        setRegistrationError("");
        const app_id = web_data?.app_id;
        let token = localStorage.getItem("token");
        let org_data = JSON.parse(localStorage.getItem(ORG_DATA));

        const payload = {
            data: {
                name: data.website_name,
                domain: data.website_url,
                ip_address: data.ip_address,
            },
        };

        try {
            setLoading(true);
            await axios.post(BASE_URL + '/appser/api/apps/' + app_id + '/config/create', payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    OrgId: org_data?.orgId
                }
            })
            .then((response) => {
                // Simulate API request delay for demonstration purposes
                // await new Promise((resolve) => setTimeout(resolve, 1500));

                // Set success message for demonstration
                setRegistrationSuccess(true);
                setLoading(false);
                setTimeout(() => {
                    setRegistrationSuccess(false);
                    reset(); // Reset form fields
                    navigate('/' + app_id + '/website/list');
                }, 1500);
            })
            .catch((err) => {
                if (err?.response?.status === 422) {
                    let errMsg = err.response?.data?.msg;
                    console.error(errMsg);
                    setRegistrationError(errMsg);
                }
                setLoading(false);
            })
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // Hide loading message
        }
    };

    return (
        <>
            <div className="main-content">
                <Container className="website-sec mt-30 pt-0" id="website_sec">
                    <div className="head-sec">
                        <h3 className="inner-page-title">Website</h3>
                    </div>
                    <div className="row website-content-sec">
                        <div className="m-auto col-lg-6">
                            {loading && <div className="loading-message text-center">Loading...</div>}
                            {registrationSuccess && (
                                <div className="success-message">Website added successfully.</div>
                            )}
                            <form onSubmit={handleSubmit(onSubmit)} className="create-form">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-field">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-field">
                                            <input
                                                type="text"
                                                id="website_name"
                                                name="website_name"
                                                {...register('website_name')}
                                            />
                                        </div>
                                        {errors.website_name && <span className="error-msg">{errors.website_name.message}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-field">
                                            <label htmlFor="website_url">URL</label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-field">
                                            <input
                                                type="text"
                                                id="website_url"
                                                name="website_url"
                                                {...register('website_url')}
                                            />
                                        </div>
                                        {errors.website_url && <span className="error-msg">{errors.website_url.message}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-field">
                                            <label htmlFor="ip_address">IP Address</label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-field">
                                            <input
                                                type="text"
                                                id="ip_address"
                                                name="ip_address"
                                                {...register('ip_address')}
                                            />
                                        </div>
                                        {errors.ip_address && <span className="error-msg">{errors.ip_address.message}</span>}
                                    </div>
                                </div>
                                {registrationError && <div className="error-message">{registrationError}</div>}                
                                <div className="form-submit-btn">
                                    <button type="submit" className="register_btn" disabled={loading}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Website;
