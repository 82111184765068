import React from 'react';
import { useEffect, useState } from "react";
import download from '../../Assets/Images/download.png';
import axios from 'axios';
import { APP_ID, ORG_DATA } from '../../Components/Util/Constant';
import { Link, useNavigate } from 'react-router-dom';

const List = () => {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [appsList, setAppsList] = useState([]);
  const [orgData, setOrgData] = useState({});

  const fetchAppsList = async (orgId) => {
    let token = localStorage.getItem("token");
    if (orgId) {
      await axios
        .get(`${BASE_URL}/appser/api/apps/list`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            OrgId: orgId
          },
      })
      .then((response) => {
          return response.data;
      })
      .then((data) => {
        setAppsList(data.data);
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.status === 401) {
            localStorage.clear();
            navigate('/login');
        }
      });
    }
  };

  const addLocal = (app_id) => {
    localStorage.setItem(APP_ID, app_id)
  }

  useEffect(() => {
    let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
    setOrgData(org_data);
  }, [])

  useEffect(() => {
    fetchAppsList(orgData.orgId);
  }, [orgData])
  
  return (
    <div className="main-content qa-list-sec">
      <div className="container">
        <div className="row">
        {appsList && appsList?.map((app, indx ) => {
          return (
            <Link to={`/`+ app?.id +`/website/list`} key={indx}  onClick={() => addLocal(app?.id)} className="app-col col-md-3 mb-4">
                <div className='application-card activated'>
                  {/* {app?.is_installed === false &&
                    <div class="application-icons">  
                      <img src={download} />              
                  </div>
                  } */}
                  <h3>{app.name}</h3>
                  <p>{app.description}</p>
                </div>
            </Link>
          )
        })}
          {/* <div className="col-md-3 mb-4">
              <div className='application-card'>
              <div class="application-icons">  
                 <img src={download} />              
              </div>
                <h3>TIME</h3> 
                <p>Helps you manage your company’s Time Sheet Process</p>
              </div>
          </div>
          <div className="col-md-3 mb-4">
              <div className='application-card'>
              <div class="application-icons">                
                <img src={download} />
              </div>
                <h3>ECOMSER</h3>
                <p>Helps you manage your company’s product list for public product display. This has SEO Plugin.</p>
              </div>
          </div>
          <div className="col-md-3 mb-4">
              <div className='application-card'>
              <div class="application-icons">                
              <img src={download} />
              </div>
                <h3>HIRE IN HURRY</h3>
                <p>Hire in Hurry - Vendor Application is for managing your recruitment and staff augmentation business </p>
              </div>
          </div>
          <div className="col-md-3 mb-4">
              <div className='application-card'>
              <div class="application-icons">                
              <img src={download} />
              </div>
                <h3>WSADM</h3>
                <p>Helps you manage your company’s website basic contents and blog contents. </p>
              </div>
          </div> */}
          {/* <div className="col-md-3 mb-4">
              <div className='application-card'>
                <h3>MEMSER</h3>
                <p>Pre-installed for all organizations. Helps you manage your company’s Time Sheet Process</p>
              </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default List;
