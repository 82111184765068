import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import './career.css';
import axios from "axios";
import Pagination from '../Util/pagination';

const Career = () => {
    const [careerList, setCarrerList] = useState([]);
    const BASE_URL = process.env.REACT_APP_CAREER_API_URL;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getCarrerList(currentPage);
    }, [currentPage]);

    const getCarrerList = async (page) => {
        try {
            let response = await axios.get(BASE_URL + `job_posting?page=${page}&paginate=Y`);
            setCarrerList(response.data?.data);
         } catch (error) {
               console.error(error);
         }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Fetch data for the new page based on the pageNumber
        // Example: fetchData(pageNumber);
    };

    return (
        <section className="career-view">
            <div className="container">
                <h3 className="mb-4 section-title">Job Positions</h3>
                <div className="row">
                    {careerList?.data?.map(item => (
                        <div className="col-lg-6 col-md-12 mb-3" key={item.id}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <p className="card-text climb_1"> {item.location? (<span className="card-desc">Location:  {item.location}</span>): ''} {item.type && <span className="card-desc">Job Type: {item.type}</span> }</p>
                                    <p className="mb-0">{item.location || item.type? '' : <span className="empty-span">Location</span>} </p>
                                    <div className="row">
                                        <span className="col-lg-3 col-5 pt-2 post-date">{item.created_at}</span>
                                        <span className="col-lg-3 col-2 p-0 pt-2 ado-id">#{item.ado_id}</span>
                                        <div className="col-lg-3 col-5 pl-0 offset-lg-3">
                                            <a className="btn btn-primary btn-color float-right d-block" href={`/careers/` + item.ado_id}>Job Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {careerList.total > 10 &&
                    <Pagination paginationData={careerList} onPageChange={handlePageChange} />
                }
            </div>
        </section>
    )
}

export default Career;