import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";

const Create = () => {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationError, setRegistrationError] = useState('');

  const schema = yup.object().shape({
    name: yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z]/, 'Name should start with an alphabet'),
    website: yup.string()
    .required('Website is required')
    .matches(/^[A-Za-z]/, 'Website should start with an alphabet')
    .matches(/\.[a-zA-Z]+$/, 'Invalid URL format'),
    address: yup.string().trim().required('Address is required')
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (data) => {
    try {
      setRegistrationError('');
      const payload = data;
      let token = localStorage.getItem("token");

      axios.post(BASE_URL + '/orgser/api/org/create', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => {
          setRegistrationSuccess(true);
          setTimeout(() => {
            setRegistrationSuccess(false);
            reset(); // Reset form fields
            navigate('/organization/list');
          }, 1500);
        })
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.msg || 'Creation failed';
            setRegistrationError(errorMessage);
          } else {
            setRegistrationError('An error occurred while registering');
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container id="org_section" className="org-section mt-30">
        <div className="head-sec">
          <h3 className="inner-page-title">Organization</h3>
        </div>
        <Row className="org-content-sec">
          <Col className="m-auto" lg={6}>
          {registrationSuccess && (
            <div className="success-message">Organization added successfully.</div>
          )}
            <form onSubmit={handleSubmit(handleFormSubmit)} className="create-form">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-field">
                    <label htmlFor="name">Name</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-field">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      {...register("name")} // Use register here
                    />
                  </div>
                  {errors.name && <span className="error-msg">{errors.name.message}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-field">
                    <label htmlFor="address">Address</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-field">
                    <textarea
                      id="address"
                      name="address"
                      onKeyPress={(e) => {
                        if (e.which === 32 && e.target.selectionStart === 0) {
                            e.preventDefault();
                        }
                    }}
                      {...register("address")} // Use register here
                    ></textarea>
                  </div>
                  {errors.address && <span className="error-msg">{errors.address.message}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-field">
                    <label htmlFor="website">Website</label>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-field">
                    <input
                      type="text"
                      id="website"
                      name="website"
                      {...register("website")} // Use register here
                    />
                  </div>
                  {errors.website && <span className="error-msg">{errors.website.message}</span>}
                </div>
              </div>
              {registrationError && <div className="error-message">{registrationError}</div>}
              <div className="form-submit-btn">
                <button type="submit" className="register_btn">Submit</button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Create;
