import "./Product.css";
import Sidebar from "../../Components/Util/Sidebar/Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import Editor from '../../Components/Util/Editor';
import { APP_ID, ORG_DATA, SAVE_FILER_CAT, TOKEN } from "../../Components/Util/Constant";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Product = () => {
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState('');
  const [registrationError, setRegistrationError] = useState("");
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const BASE_URL_WSADMPR = process.env.REACT_APP_API_BASE_URL_WSADMPR;
  const [textItems, setTextItems] = useState([]);
  const [websiteList, setWebsiteList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const fileInputRef = useRef(null);
  const cat_data = useParams();
  const [editorHtml, setEditorHtml] = useState('');
  const [editorProdAttrHtml, setEditorProdAttrHtml] = useState('');
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectedWebId, setSelectedWebId] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [catDataImages, setCatDataImages] = useState([]);
  const [selectedCategoryOne, setSelectedCategoryOne] = useState("");
  const [selectedCategoryTwo, setSelectedCategoryTwo] = useState("");
  const [selectedCategoryThree, setSelectedCategoryThree] = useState("");
  const [selectedCategoryFour, setSelectedCategoryFour] = useState("");
  const [selectedCategoryFive, setSelectedCategoryFive] = useState("");
  const [isCategoryTwoDisabled, setIsCategoryTwoDisabled] = useState(true); 
  const [isCategoryThreeDisabled, setIsCategoryThreeDisabled] = useState(true); 
  const [isCategoryFourDisabled, setIsCategoryFourDisabled] = useState(true); 
  const [isCategoryFiveDisabled, setIsCategoryFiveDisabled] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [hiddenImages, setHiddenImages] = useState([]);
  const [isLoadingIndex, setIsLoadingIndex] = useState(false);
  const filter_data = localStorage.getItem(SAVE_FILER_CAT);
  const [imgsuccessMsg, setimgSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState(null); // Declare errorMsg state
  const [errorCategoryOne, setErrorCategoryOne] = useState("");
  const [errorCategoryTwo, setErrorCategoryTwo] = useState("");
  const [errorCategoryThree, setErrorCategoryThree] = useState("");
  const [errorCategoryFour, setErrorCategoryFour] = useState("");
  const [errorCategoryFive, setErrorCategoryFive] = useState("");
  
  const getProductyById = async (website_id, prod_id) => {
    let token = localStorage.getItem(TOKEN);
    if (token) {
      await axios
        .get(`${BASE_URL_WSADMPR}/admin/product/${prod_id}`, {
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
            'Api-Key': website_id,
          },
      })
      .then((response) => {
          return response.data;
      })
      .then((data) => {
        let prod_resp = data.data;
        setProductDetails(prod_resp);
        setValue("name", prod_resp.name);
        setEditorHtml(prod_resp.description? prod_resp.description : '');
        setValue("page_url", prod_resp.page_url);
        setValue("perma_link", prod_resp.perma_link);
        setValue("price", prod_resp.price);
        setValue("seo_description", prod_resp.seo?.meta_description);
        setValue("seo_title", prod_resp.seo?.title);
        setValue("categoriesone", prod_resp.category_one?.id);
        setValue("categoriestwo", prod_resp.category_two?.id);
        setValue("categoriesthree", prod_resp.category_three?.id);
        setValue("categoriesfour", prod_resp?.category_four?.id);
        setValue("categoriesfive", prod_resp?.category_five?.id);
        // setValue("product_attributes", prod_resp.additional_information);
        setEditorProdAttrHtml(prod_resp.additional_information? prod_resp.additional_information: '');
        setSelectedCategoryOne(prod_resp.category_one?.id ? prod_resp.category_one?.id :  "");
        setSelectedCategoryTwo(prod_resp.category_two?.id ? prod_resp.category_two?.id : "");
        setSelectedCategoryThree(prod_resp.category_three?.id ? prod_resp.category_three?.id : "");
        setSelectedCategoryFour(prod_resp.category_four?.id ? prod_resp.category_four?.id : "");
        setSelectedCategoryFive(prod_resp.category_five?.id ? prod_resp.category_five?.id : "");
        setIsCategoryTwoDisabled(prod_resp.category_two?.id ? "" : true);
        setIsCategoryThreeDisabled(prod_resp.category_three?.id ? "" : true);
        setIsCategoryFourDisabled(prod_resp.category_four?.id ? "" : true);
        setIsCategoryFiveDisabled(prod_resp.category_five?.id ? "" : true);
        setPreviewUrl(prod_resp.seo?.meta_img);
        const catDataImagesFromApi = prod_resp.images;
        setCatDataImages(prod_resp.images);
        setTextItems([]);
        if (prod_resp.seo.keywords) {
          const keywordValues = Object.values(prod_resp.seo.keywords);
          for (const keyword of keywordValues) {
              setTextItems((prevTextItems) => [...prevTextItems, keyword]);
          }
        }
      })
      .catch((err) => {
          console.log(err);
      });
    }
  }
  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };

  const handleImageChange = async (event) => {
    const selectedFile = event.target.files[0];
    try {
        if (selectedFile?.type.startsWith('image/')) {
            setValue('seo_img', selectedFile);
            await trigger('seo_img');
            setSelectedImage(selectedFile);
            const imageUrl = URL.createObjectURL(selectedFile);
            setPreviewUrl(imageUrl);
            setErrorMsg('');
        } else {
            throw new Error('Invalid image type'); // Throw an error for invalid extension
        }
    } catch (error) {
        console.error(error);
        // Display the error message to the user, e.g., by setting it in state
        setErrorMsg('Invalid file format. Please select a valid image (JPEG, PNG, GIF).');
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Clear the file input
        }
    }
  };

  const handleDeletePreview = () => {
    setSelectedImage(null);
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input
    }
  };

  const handleRemoveText = (index) => {
    const updatedItems = textItems.filter((_, i) => i !== index);
    setTextItems(updatedItems);
  };

  let token = localStorage.getItem(TOKEN);
  //Fecth category
  const fetchcategoryList = async (web_key) => {
    let token = localStorage.getItem(TOKEN);
    if (token) {
      await axios
        .get(`${BASE_URL_WSADMPR}/admin/category/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Api-Key": web_key,
          },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setCategoryList(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchWebsiteList = async () => {
    let token = localStorage.getItem(TOKEN);
    let app_id = localStorage.getItem(APP_ID);
    let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
    if (org_data?.orgId && app_id && token) {
      await axios
        .get(`${BASE_URL}/appser/api/apps/`+ app_id +`/configs`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            OrgId: org_data?.orgId
          },
      })
      .then((response) => {
          return response.data;
      })
      .then((data) => {
          setWebsiteList(data.data);
          if (cat_data.web_id) {
            setIsEditMode(true);
            // setValue('web_list', cat_data.web_id);
          } else {
            fetchcategoryList(data.data[0].id)
          }
          setTimeout(() => {
            if (filter_data) {
              let wdt = JSON.parse(filter_data);
              setValue('web_list', wdt?.web_id);
            }
          }, 100);
      })
      .catch((err) => {
          console.log(err);
      });
    }
  };

  useEffect(() => {
    if (cat_data.prod_id) {
        setIsEditMode(true);
        // setValue('web_list', cat_data.web_id);
      }
      if (selectedWebId !== cat_data.web_id && cat_data.prod_id) {
        setSelectedWebId(cat_data.web_id);
        getProductyById(cat_data.web_id, cat_data.prod_id);
        fetchcategoryList(cat_data.web_id);
    }
  }, [websiteList, cat_data]);

  // Define yup schema for form validation
  const schema = yup.object().shape({
    seo_img: isEditMode
    ? yup.mixed()
    : yup
        .mixed()
        .test('fileFormat', 'Invalid image format', (value) => {
          if (!value) {
            return true;
          }
          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
          return !allowedTypes.includes(value.type) ? this.createError({ message: 'Invalid image format' }) : true;
        })
        .required('SEO Image is required'),
    name: yup
      .string()
      .trim()
      .required("Product name is required")
      .matches(
        /^[A-Za-z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\/\\\-'"`|]+$/,
        "Product Name should accept only alphabet, numbers and special character"
      ),
    seo_title: yup
      .string()
      .trim()
      .required("Seo Title is required")
      .matches(
        /^[A-Za-z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\/\\\-'"`|]+$/,
        "Seo Title should accept only alphabet, numbers and special character"
      ),
      price: yup
      .string()
      .trim()
      .required("Price is required")
      .matches(
        /^[0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\/\\\-'"`|€£¥]*$/, // Change "+" to "*"
        "Price can only contain numbers and special characters"
      ),
      web_list: yup.string().trim().required('Website is required'),
  });
  
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(schema), // Use yupResolver here
  });

  const handleAddText = () => {
    let formData = watch();
    let inputText = formData?.seo_keywords;
    if (inputText?.trim() !== "") {
      setTextItems([...textItems, inputText]);
      setValue("seo_keywords", "");
    }
  };

  useEffect(() => {
    let web_list_id = watch('web_list');
    if (web_list_id) {
      fetchcategoryList(watch('web_list'));
    }
    if (web_list_id != '') {
        let wb_id = formatName(web_list_id);
        let wb_dt = {"web_id": wb_id}
        localStorage.setItem(SAVE_FILER_CAT, JSON.stringify(wb_dt));
    }
  }, [watch('web_list')])


  useEffect(() => {
      setEditorLoaded(true);
      fetchWebsiteList();
  }, []);
  
  const handleAttachImageChange = (event) => {
    const newSelectedFiles = Array.from(event.target.files);
    setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...newSelectedFiles]);
  };

  const handleRemoveImage = (indexToRemove, isFromSelectedImages) => {
    if (isFromSelectedImages) {
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages.filter((_, index) => index !== indexToRemove)
      );
    } else {
      setCatDataImages((prevCatDataImages) =>
        prevCatDataImages.filter((_, index) => index !== indexToRemove)
      );
    }
  };
  

  const fileInputRefSeoImg = useRef(null);

  const handleFormSubmit = async (data) => {
    try {
      let web_key = data.web_list;
      setRegistrationError("");
      const payload = new FormData();
      payload.append("name", data.name);
      payload.append("description", editorHtml);
      payload.append("seo_description", data.seo_description);
      payload.append("perma_link", data.perma_link);
      payload.append("page_url", data.page_url);
      payload.append("price", data.price);
      payload.append("seo_keywords", JSON.stringify(textItems)); // Serialize the array
      payload.append("seo_title", data.seo_title);
      payload.append("product_attributes", editorProdAttrHtml)
      if (selectedImage) {
        payload.append("seo_img", selectedImage);
      }
      const selectedCategories = [];
      const dropdownIds = [
        "categoriesone",
        "categoriestwo",
        "categoriesthree",
        "categoriesfour",
        "categoriesfive",
      ];
      dropdownIds.forEach((dropdownId) => {
        const selectedValue = document.getElementById(dropdownId).value;
        if (selectedValue) {
          selectedCategories.push(selectedValue);
        }
      });
      payload.append("categories", JSON.stringify(selectedCategories));
      


      for (const element of selectedImages) {
        const headers = {
          "Content-Disposition": `form-data; name="images[]"; filename="${element.name}"`,
          "Content-Type": element.type,
        };
        payload.append("images[]", element, headers);
      }
      if (isEditMode) {
      // Append remaining images to payload
      // const updatedSelectedImages = selectedImages.filter((_, index) => !imagesToRemove.includes(index));

      // for (const element of updatedSelectedImages) {
      //   const headers = {
      //     "Content-Disposition": `form-data; name="images[]"; filename="${element.name}"`,
      //     "Content-Type": element.type,
      //   };
      //   payload.append("images[]", element, headers);
      // }
        let prod_id = cat_data.prod_id;
        axios.post(`${BASE_URL_WSADMPR}/admin/product/${prod_id}/update`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
              "Api-Key": web_key,
            },
          })
          .then((response) => {
            setSuccessMsg('Product Updated successfully');
            reset();
            setSelectedImage(null);
            setPreviewUrl(null);
            setEditorHtml("");
            setEditorProdAttrHtml('');
            setTimeout(() => {
              setSuccessMsg('');
              navigate('/product/list');
            }, 1500);
          })
          .catch((error) => {
            if (error.response) {
              const errorMessage = error.response.data.msg || "Creation failed";
              setRegistrationError(errorMessage);
            } else {
              setRegistrationError("An error occurred while registering");
            }
          });
      } else {
        axios
          .post(`${BASE_URL_WSADMPR}/admin/product/add`, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
              "Api-Key": web_key,
            },
          })
          .then((response) => {
            setSuccessMsg('Product Added successfully');
            reset();
            setSelectedImage(null);
            setPreviewUrl(null);
            setEditorHtml("");
            setEditorProdAttrHtml('');
            setTimeout(() => {
              setSuccessMsg('');
              navigate('/product/list');
            }, 1500);
          })
          .catch((error) => {
            if (error.response) {
              const errorMessage = error.response.data.msg || "Creation failed";
              setRegistrationError(errorMessage);
            } else {
              setRegistrationError("An error occurred while registering");
            }
          });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.target.value) {
        e.target.value = e.target.value.replace(/^\s+/, '');
        e.target.value = e.target.value.replace(/\s{2,}/g, ' ');
    }
    trigger('name');
  };

  const formatName = (name) => {
    return name.replace(/\s+/g, '-').toLowerCase(); // Replace multiple spaces with a single hyphen
  }

  useEffect(() => {
    const nameValue = watch("name");
    let perma = formatName(nameValue);
    setValue("perma_link", perma);
  }, [watch("name")]);
  
  const handleCategoryOneChange = (event) => {
    const value = event.target.value;
    setSelectedCategoryOne(value);
    setErrorCategoryOne("");
    if(selectedCategoryTwo == value || selectedCategoryThree == value || selectedCategoryFour == value || selectedCategoryFive == value) {
      setErrorCategoryOne("Cannot choose the same category as other category.");
      setSelectedCategoryOne("");
      setSelectedCategoryTwo("");
      setSelectedCategoryThree("");
      setIsCategoryTwoDisabled(value === ""); 
      setSelectedCategoryFour("");
      setSelectedCategoryFive("");
    }
    if(value === '') {
    setSelectedCategoryTwo("");
    setSelectedCategoryThree("");
    setIsCategoryTwoDisabled(value === ""); 
    setSelectedCategoryFour("");
    setSelectedCategoryFive("");
    } else {
      setIsCategoryTwoDisabled(value === ""); 
    }
  };

  const handleCategoryTwoChange = (event) => {
    const value = event.target.value;
    setSelectedCategoryTwo(value);
    setErrorCategoryTwo("");
    if(selectedCategoryOne == value || selectedCategoryThree == value || selectedCategoryFour == value || selectedCategoryFive == value) {
      setErrorCategoryTwo("Cannot choose the same category as other category.");
      setSelectedCategoryTwo("");
      setSelectedCategoryThree("");
      setIsCategoryThreeDisabled(value === ""); 
      setSelectedCategoryFour("");
      setSelectedCategoryFive("");
    } 
    if(value === '') {
    setSelectedCategoryThree("");
    setIsCategoryThreeDisabled(value === ""); 
    setSelectedCategoryFour("");
    setSelectedCategoryFive("");
    } else {
      setIsCategoryThreeDisabled(false); 
    }
  };

  const handleCategoryThreeChange = (event) => {
    const value = event.target.value;
    setSelectedCategoryThree(value);
    setErrorCategoryThree("");
    if(selectedCategoryOne == value || selectedCategoryTwo == value || selectedCategoryFour == value || selectedCategoryFive == value) {
      setErrorCategoryThree("Cannot choose the same category as other category.");
      setSelectedCategoryFour("");
      setSelectedCategoryThree("");
      setIsCategoryFourDisabled(value === ""); 
      setSelectedCategoryFive("");
    } 
    if(value === '') {
    setSelectedCategoryFour("");
    setIsCategoryFourDisabled(value === ""); 
    setSelectedCategoryFive("");
    } else  {
    setIsCategoryFourDisabled(value === ""); 
    }
  };

  const handleCategoryFourChange = (event) => {
    const value = event.target.value;
    setSelectedCategoryFour(value);
    setErrorCategoryFour("");
    if(selectedCategoryOne == value || selectedCategoryThree == value || selectedCategoryTwo == value || selectedCategoryFive == value) {
      setErrorCategoryFour("Cannot choose the same category as other category.");
      setSelectedCategoryFour("");
      setSelectedCategoryFive("");
    setIsCategoryFiveDisabled(value === ""); 
    } 
    if(value === '') { 
    setSelectedCategoryFive("");
    setIsCategoryFiveDisabled(value === ""); 
    }  else {
    setIsCategoryFiveDisabled(value === ""); 
    }
  };

  const handleCategoryFiveChange = (event) => {
    const value = event.target.value;  
    setSelectedCategoryFive(value);
    setErrorCategoryFour("");
    if(selectedCategoryOne == value || selectedCategoryTwo == value || selectedCategoryFour == value || selectedCategoryFive == value) {
      setErrorCategoryFive("Cannot choose the same category as other category.");
      setSelectedCategoryFive("");
    } 
   
  };

  const handleEditRemoveImage = (indexToRemove, isFromSelectedImages) => {
    const confirmed = window.confirm("Are you sure you want to delete this image?");
   
    if (!confirmed) {
      return; // Abort if user cancels the deletion
    }
    let web_id = cat_data.web_id;
    setIsLoadingIndex(indexToRemove);
    if (isFromSelectedImages) {
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages.filter((_, index) => index !== indexToRemove)
      );
    } else {
      
      const imageId = catDataImages[indexToRemove].id;
      
      fetch(`${BASE_URL_WSADMPR}/admin/product/img/${imageId}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json", // Change to "application/json"
          Authorization: `Bearer ${token}`,
          "Api-Key": web_id,
        },
      })
      .then((response) => {
        
        if (response.status === 200) {
          
          setHiddenImages(prevHiddenImages => [...prevHiddenImages, indexToRemove]);
          setimgSuccessMsg('Product Image Deleted successfully');
      
          // Automatically clear the success message after a delay (e.g., 3 seconds)
          setTimeout(() => {
            setIsLoadingIndex(null); // Set loading to false after completion
            setimgSuccessMsg('');
          }, 3000); // Adjust the time as needed
        }
      })
      .catch(error => {
        setIsLoadingIndex(null); // Set loading to false if an error occurs
        console.error('Error removing image:', error);
        setimgSuccessMsg('Error deleting product image'); // Show error message
      });
    }
  };

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="layout-sec">
          <Container id="prod_cat_section" className="prod-cat-section mt-30">
            <div className="head-sec">
              <h3 className="inner-page-title">Product</h3>
            </div>
            <Row className="prod-cat-content-sec">
              <Col className="m-auto" lg={10}>
                <form
                  onSubmit={handleSubmit(handleFormSubmit)}
                  className="create-form"
                >
                  <div className="col-6 m-auto">
                    <label htmlFor="web_list">
                      Websites <span className="required-label">*</span>
                    </label>
                    <select
                      id="web_list"
                      name="web_list"
                      className="website-dropdown"
                      {...register("web_list")}
                    >
                      {websiteList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option?.data?.name}
                        </option>
                      ))}
                    </select>
                    {errors.web_list && (
                      <span className="error-msg">{errors.web_list.message}</span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor="name">
                          Name <span className="required-label">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          {...register("name")}
                          onKeyUp={handleKeyPress}
                        />
                      </div>
                      {errors.name && (
                        <span className="error-msg">{errors.name.message}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor="perma_link">Perma Link </label>
                        <input
                          type="text"
                          id="perma_link"
                          name="perma_link"
                          {...register("perma_link")}
                          readOnly
                        />
                      </div>
                      {errors.perma_link && (
                        <span className="error-msg">
                          {errors.perma_link.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor="price">
                          Price <span className="required-label">*</span>
                        </label>
                        <input
                          type="text"
                          id="price"
                          name="price"
                          {...register("price")}
                          onKeyUp={handleKeyPress}
                        />
                      </div>
                      {errors.price && (
                        <span className="error-msg">{errors.price.message}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor="page_url">Page URL</label>
                        <input
                          type="text"
                          id="page_url"
                          name="page_url"
                          {...register("page_url")}
                          onKeyUp={handleKeyPress}
                        />
                      </div>
                      {errors.page_url && (
                        <span className="error-msg">{errors.page_url.message}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <label>Product Images</label>
                        <div className="d-flex">
                          <input
                            type="file"
                            id="attachment"
                            name="attachment"
                            accept=".jpg, .jpeg, .png"
                            multiple
                            onChange={handleAttachImageChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="image-preview-container">
                            {selectedImages.map((image, index) => (
                              <div className="image-preview" key={index}>
                                {" "}
                                {}
                                <img
                                  className="preview-image"
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                />
                                <span
                                  className="remove-icon"
                                  onClick={() => handleRemoveImage(index, true)}
                                >
                                  {" "}
                                  {}
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </span>
                              </div>
                            ))}

                            {catDataImages.map((imageUrl, index) => {
                              if (!hiddenImages.includes(index)) {
                                return (
                                  <div className="image-preview" key={`saved-preview-${index}`}>
                                    <img
                                      className="preview-image"
                                      src={imageUrl.url}
                                      alt={`Saved Preview ${index}`}
                                    />
                                    {/* You might want to add a different icon or styling for saved images */}
                                    <span
                                      className="remove-icon"
                                      onClick={() => handleEditRemoveImage(index, false)}
                                    >
                                      <FontAwesomeIcon icon={faTimesCircle} />
                                    </span>
                                    {isLoadingIndex === index && (
                                      <div className="popup-loader">
                                        <FontAwesomeIcon icon={faSpinner} spin /> Deleting...
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                      {imgsuccessMsg != '' && (
                          <div className="success-msg">{imgsuccessMsg}</div>
                        )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor="seo_title">
                          Seo Title <span className="required-label">*</span>
                        </label>
                        <input
                          type="text"
                          id="seo_title"
                          name="seo_title"
                          {...register("seo_title")}
                          onKeyUp={handleKeyPress}
                        />
                      </div>
                      {errors.seo_title && (
                        <span className="error-msg">
                          {errors.seo_title.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <label htmlFor="seo_img">
                          Meta Image <span className="required-label">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="img-preview">
                            {previewUrl && (
                              <>
                                {/* <i
                                      className="fa fa-close close-btn"
                                      onClick={handleDeletePreview}
                                    ></i> */}
                                <img
                                  src={previewUrl}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                  }}
                                />
                              </>
                            )}
                          </div>
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                     
                      {errorMsg && <span className="error-msg">{errorMsg}</span>}

                      {errors.seo_img && (
                        <span className="error-msg">{errors.seo_img.message}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col=md-12">
                          <div className="form-field">
                            <label htmlFor="seo_description">SEO Description</label>
                            <textarea
                              type="text"
                              id="seo_description"
                              name="seo_description"
                              {...register("seo_description")}
                              onKeyUp={handleKeyPress}
                            ></textarea>
                          </div>
                          {errors.seo_description && (
                            <span className="error-msg">
                              {errors.seo_description.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="form-field">
                            <label htmlFor="seo_keywords">Seo Keywords</label>
                            <div className="app-chip">
                              <div className="chip-list">
                                {textItems.map((item, index) => (
                                  <div key={index} className="chip">
                                    {item}
                                    <i
                                      className="fa fa-close chip-close"
                                      onClick={() => handleRemoveText(index)}
                                    ></i>
                                  </div>
                                ))}
                              </div>
                              <textarea
                                id="seo_keywords"
                                name="seo_keywords"
                                className="text-area"
                                rows={1}
                                placeholder="Enter keyword and press Enter"
                                {...register("seo_keywords")}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleAddText();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <label htmlFor="description">Description</label>
                        <Editor
                          className="desc-quil"
                          id="description"
                          name="description"
                          onChange={(data) => {
                            setEditorHtml(data);
                          }}
                          value={editorHtml}
                          editorLoaded={editorLoaded}
                        />
                      </div>
                      {errors.description && (
                        <span className="error-msg">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                    <div className="col=md-12">
                      <div className="form-field">
                        <label htmlFor="product_attributes">
                          Additional Information
                        </label>
                        {/* <textarea
                          type="text"
                          id="product_attributes"
                          name="product_attributes"
                          {...register("product_attributes")}
                        ></textarea> */}
                        <Editor
                          className="desc-quil"
                          id="product_attributes"
                          name="product_attributes"
                          onChange={(data) => {
                            setEditorProdAttrHtml(data);
                          }}
                          value={editorProdAttrHtml}
                          editorLoaded={editorLoaded}
                        />
                      </div>
                      {errors.product_attributes && (
                        <span className="error-msg">
                          {errors.product_attributes.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-field">
                            <select
                              id="categoriesone"
                              name="categories[]"
                              className="category-dropdown"
                              {...register("categoriesone")}
                              value={selectedCategoryOne}
                              onChange={handleCategoryOneChange}
                            >
                              <option value="">Category Level 1</option>
                              {categoryList?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {errorCategoryOne &&  <span className="error-msg">{errorCategoryOne}</span>}
                            {errors.categories && (
                              <span className="error-msg">
                                {errors.categories.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-field">
                            <select
                              id="categoriestwo"
                              name="categories[]"
                              className="category-dropdown"
                              {...register("categoriestwo")}
                              value={selectedCategoryTwo}
                              onChange={handleCategoryTwoChange}
                              disabled={
                                selectedCategoryOne == ""
                              }
                            >
                              <option value="">Category Level 2</option>
                              {categoryList?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {errorCategoryTwo &&  <span className="error-msg">{errorCategoryTwo}</span>}
                            {errors.categories && (
                              <span className="error-msg">
                                {errors.categories.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-field">
                            <select
                              id="categoriesthree"
                              name="categories[]"
                              className="category-dropdown"
                              {...register("categoriesthree")}
                              value={selectedCategoryThree}
                              onChange={handleCategoryThreeChange}
                              disabled={
                                selectedCategoryTwo == ""
                              }
                            >
                              <option value="">Category Level 3</option>
                              {categoryList?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {errorCategoryThree &&  <span className="error-msg">{errorCategoryThree}</span>}
                            {errors.categories && (
                              <span className="error-msg">
                                {errors.categories.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-field">
                            <select
                              id="categoriesfour"
                              name="categories[]"
                              className="category-dropdown"
                              {...register("categoriesfour")}
                              value={selectedCategoryFour}
                              onChange={handleCategoryFourChange}
                              disabled={
                              
                                selectedCategoryThree == ""
                              }
                            >
                              <option value="">Category Level 4</option>
                              {categoryList?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {errorCategoryFour &&  <span className="error-msg">{errorCategoryFour}</span>}
                            {errors.categories && (
                              <span className="error-msg">
                                {errors.categories.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-field">
                            <select
                              id="categoriesfive"
                              name="categories[]"
                              className="category-dropdown"
                              {...register("categoriesfive")}
                              value={selectedCategoryFive}
                              onChange={handleCategoryFiveChange}
                              disabled={
                                selectedCategoryFour == ""
                              }
                            >
                              <option value="">Category Level 5</option>
                              {categoryList?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                            {errorCategoryFive &&  <span className="error-msg">{errorCategoryFive}</span>}
                            {errors.categories && (
                              <span className="error-msg">
                                {errors.categories.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-4">
                          <NavLink to="/product-category/add">
                            <button type="button" className="submit_btn">
                              Add Category
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-submit-btn d-flex">
                        {successMsg != "" && (
                          <div className="success-msg">{successMsg}</div>
                        )}
                        <button type="submit" className="submit_btn">
                          {isEditMode ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {registrationError && (
                    <div className="error-message">{registrationError}</div>
                  )}
                </form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Product;
