import './ProductCategory.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { APP_ID, ORG_DATA, SAVE_FILER_CAT, TOKEN } from '../../Components/Util/Constant';
import Sidebar from '../../Components/Util/Sidebar/Sidebar';
import { Container, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const CategoryList = () => {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const [websiteList, setWebsiteList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const BASE_URL_WSADMPR = process.env.REACT_APP_API_BASE_URL_WSADMPR;
    const filter_data = localStorage.getItem(SAVE_FILER_CAT);

    const fetchWebsiteList = async () => {
        let token = localStorage.getItem(TOKEN);
        let app_id = localStorage.getItem(APP_ID);
        let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
        if (org_data?.orgId && app_id && token) {
          await axios
            .get(`${BASE_URL}/appser/api/apps/`+ app_id +`/configs`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                OrgId: org_data?.orgId
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
              setWebsiteList(data.data);
              setSelectedOption(data.data[0].id);
              if (filter_data) {
                let wdt = JSON.parse(filter_data);
                setSelectedOption(wdt?.web_id);
              }
          })
          .catch((err) => {
              console.error(err);
              if (err?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
          });
        }
    };

    const fetchCategoryList = async (website_id) => {
        let token = localStorage.getItem(TOKEN);
        if (token) {
          await axios
            .get(`${BASE_URL_WSADMPR}/admin/category/list`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'Api-Key': website_id,
              },
          })
          .then((response) => {
              return response.data;
          })
          .then((data) => {
              setCategoryList(data.data);
          })
          .catch((err) => {
              console.log(err);
              setCategoryList([]);
          });
        }
    };

    const onChangeWebsite = (e) => {
        let web_id = e.target?.value;
        if (web_id) {
            fetchCategoryList(web_id);
            setSelectedOption(web_id);
            let wb_dt = {"web_id": web_id}
            localStorage.setItem(SAVE_FILER_CAT, JSON.stringify(wb_dt));
        }
    }

    useEffect(() => {
        fetchCategoryList(selectedOption);
    }, [selectedOption])

    useEffect(() => {
        fetchWebsiteList();
    }, [])

    return (
        <>
            <div className="dashboard-container">
                <Sidebar />
                <div className='layout-sec'>
                <Container id="cat_list" className="cat-list mt-30">
                    <div className="head-sec">
                        <h3 className="inner-page-title">Product Category</h3>
                        <Link className="add-btn" to={"/product-category/add"}>Add Category</Link>
                    </div>
                    <div className='col-6 m-auto'>
                        <label htmlFor="web_list">Websites</label>
                        <select id='web_list' name='web_list' className='website-dropdown' value={selectedOption || ''} onChange={onChangeWebsite}>
                            {websiteList.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option?.data?.name}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="cat-list-table">
                        <Table striped className="table-number">
                            <thead className="table-head">
                                <tr>
                                    <th className="table-col-name">Name</th>
                                    <th className="table-col-title">Seo Title</th>
                                    <th className="table-col-url">Page URL </th>
                                </tr>
                            </thead>
                        </Table>
                        <div className="table-body-container">
                            <Table striped className="table-number">
                                <tbody>
                                    {categoryList?.length > 0 && categoryList?.map((catData, indx) => {
                                        return (
                                            <tr className="table-row" key={catData?.id}>
                                                <td className="table-col-name"><Link to={`/${selectedOption}/product-category/edit/${catData?.id}`}>{catData?.name}</Link></td>
                                                <td className="table-col-title">{catData?.seo_title}</td>
                                                <td className="table-col-url">{catData?.page_url}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        {!categoryList?.length > 0  && <p className="no-data">No categories found</p>}
                    </div>
                </Container>
                </div>
            </div>
        </>
    )
}

export default CategoryList;