import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink, useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TOKEN } from "../Util/Constant";

const Login = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const loginSchema = yup.object().shape({
    email: yup.string().required('Email is required')
      .matches(/^\S*$/, 'Email should not contain spaces')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Invalid email address format')
      .email('Invalid email address'),
    password: yup.string().required('Password is required'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    // Check if the user is already authenticated
    const token = localStorage.getItem(TOKEN);
    if (token) {
      navigate('/dashboard'); // Redirect to the dashboard if authenticated
    }
  }, [navigate]);

  const handleFormSubmit = async (data) => {
    setLoading(true);
    setLoginSuccess(false);
    setLoginError('');

    const payload = {
      email: data.email,
      password: data.password,
    };

    try {
      const response = await axios.post(BASE_URL + '/authser/login', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      localStorage.setItem(TOKEN, response.data.access_token);
      setLoginSuccess(true);
      window.location.href = '/organization/list';
    } catch (error) {
      if (error.response) {
        setTimeout(() => {
          setLoginError('');
        }, 3000);
        setLoginError(error.response.data.msg || 'Login failed');
      } else {
        setTimeout(() => {
          setLoginError('');
        }, 3000);
        console.log('An error occurred:', error);
        setLoginError('An error occurred while logging in');
      }
    } finally {
      setLoading(false);
    }
  };
  const location = useLocation();

  // Verification message
  const pathMessages = {
    '/verification/already': 'Already account verified!',
    '/verification/failed': 'Link has been expired or somthing else please contact the administrator!',
    '/verification/success': 'Account has been verified successfully!'
  };

  const currentPath = location.pathname;
  const message = pathMessages[currentPath] || '';
  const MESSAGE_DISPLAY_TIME = 5000; // Time in milliseconds (5 seconds)

  // Inside your Login functional component
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    if (message) {
      setShowMessage(true);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, MESSAGE_DISPLAY_TIME);

      return () => clearTimeout(timer); // Clear the timer when the component unmounts
    }
  }, [message]);

  return (
    <>
      <Container id="register_sec" className="register-sec">
        <Row className="register-content-sec">
          <Col className="m-auto" lg={5}>
            <div className="verification-message" style={{ display: showMessage ? 'block' : 'none' }}>
              {message}
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)} className="login-form">
              <div className="row">
                <div className="col-md-12">
                  {loginError && <div className="error-message text-center">{loginError}</div>}
                  {loginSuccess && <div className="success-message text-center">Login successful!</div>}
                </div>
                <div className="col-md-4">
                  <div className="form-field">
                    <label htmlFor="email">*Email</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-field">
                    <input
                      type="text"
                      id="email"
                      {...register("email")}
                    />
                  </div>
                  {errors.email && <span className="error-msg">{errors.email.message}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-field">
                    <label htmlFor="password">*Password</label>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-field">
                    <div className="password-input-container">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        {...register("password")}
                      />
                      <div className="password-toggle-icon" onClick={toggleShowPassword}>
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </div>
                    </div>
                  </div>
                  {errors.password && <span className="error-msg">{errors.password.message}</span>}
                </div>
              </div>

              <p className='login_link'>
                Want to become a DWP Member?
                <NavLink to="/register" className='login_btn'> Register</NavLink>
              </p>
              <div className="btn-div">
                <button type="submit" className="register_btn" disabled={loading}>
                  {loading ? "Loading..." : "Login"}
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
