import { useState, useContext, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { Link, Redirect } from "react-router-dom";

const Dashboard = () => {
   
    return(
        <>
            <Container id="home_sec" className="home-sec">
                <Row className="home-content-sec">
                    <Col className="offset-lg-1" lg={7}>
                        <h2 className="home-content">Welcome!</h2>
                    </Col>
                    <Col lg={4}>

                    </Col>
                   
                </Row>
            </Container>
        </>
    );
}

export default Dashboard;