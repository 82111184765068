// Sidebar.js
import React, { useEffect, useState } from 'react';
import './Sidebar.css'; 
import { NavLink, useParams } from 'react-router-dom';
import { APP_ID, ORG_DATA } from '../Constant';
import axios from 'axios';

const Sidebar = () => {
  let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [appList, setAppsList] = useState({});

  const fetchAppsList = async () => {
    let token = localStorage.getItem("token");
    if (org_data?.orgId) {
      await axios
        .get(`${BASE_URL}/appser/api/apps/list`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            OrgId: org_data?.orgId
          },
      })
      .then((response) => {
          return response.data;
      })
      .then((data) => {
        setAppsList(data.data);
      })
      .catch((err) => {
          console.log(err);
      });
    }
  };

  const addLocal = (app_id) => {
    localStorage.setItem(APP_ID, app_id)
  }

  useEffect(() => {
    fetchAppsList();
  }, [])

  return (
    <div className="sidebar">
      <div className="user-panel mt-3 pb-3">
          <div className="image text-center">
          <img src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
          </div>
      </div>
      <h5 className="text-center text-white mb-3">{org_data.name}</h5>
      <ul className="text-white side-menu">
        <li>Dashboard</li>
        <li><NavLink to ="/qa/index" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' }>Organization Application</NavLink></li>
        {/* <li><NavLink to ="/website/create" className={({isActive}) => isActive ? "nav-link active" : 'nav-link' } title="Item">Website Adminstration</NavLink></li> */}
        {/* <li>Hire In Hurry - Paltform</li> */}
        {appList?.length > 0 && appList?.map((app, indx) => {
          return (
            <li className='app-sub-menu' key={indx}>
              <NavLink
                to={`/` + app.id +`/website/list`}
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
                onClick={() => addLocal(app.id)}
              >
                {app?.name}
              </NavLink>
            </li>
        )})}
        <li className='app-sub-menu-2'>
          <NavLink
            to={`/product-category/list`}
            className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
          >
            Product Category
          </NavLink>
        </li>
        <li className='app-sub-menu-2'>
          <NavLink
            to={`/product/list`}
            className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
          >
            Products
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;