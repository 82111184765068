import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'; 
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import Home from './Components/Home/Home';
import Register from './Components/Auth/Register';
import Login from './Components/Auth/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import Create from './Pages/Organization/Organization';
import OrganizationList from './Pages/Organization/List';
import Qa_index from './Pages/QA/Index'; 
import Website_create from './Pages/Website/Index';
import WebsiteList from './Pages/Website/List';
import CategoryAdd from './Pages/ProductCategory/Category';
import CategoryList from './Pages/ProductCategory/List';
import ProductAdd from './Pages/Products/Product';
import ProductList from './Pages/Products/List';
import WebConfig from './Pages/WebConfig/WebConfig';
import Career from './Components/Career/career';
import CareerJobPosting from './Components/CareerJobPosting/careerJobPosting';
import ApplyJob from './Components/ApplyJob/applyJob';
import ContactUS from './Pages/ContactUs/contactUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={"/"}>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="" element={<Career />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/careers/:ado_id" element={<CareerJobPosting />} />
        <Route path="/careers/:ado_id/apply" element={<ApplyJob />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/verification/already" element={<Login />} />
        <Route path="/verification/failed" element={<Login />} />
        <Route path="/verification/success" element={<Login />} />
        <Route path="/organization/create" element={<Create />} />
        <Route path="/organization/list" element={<OrganizationList />} />
        <Route path="/qa/index" element={<Qa_index />} />
        <Route path="/:app_id/website/create" element={<Website_create />} />
        <Route path="/:app_id/website/list" element={<WebsiteList />} />
        <Route path="/product-category/add" element={<CategoryAdd />} />
        <Route path="/:web_id/product-category/edit/:cat_id" element={<CategoryAdd />} />
        <Route path="/product-category/list" element={<CategoryList />} />
        <Route path="/product/add" element={<ProductAdd />} />
        <Route path="/product/list" element={<ProductList />} />
        <Route path="/:web_id/product/edit/:prod_id" element={<ProductAdd />} />
        <Route path="/:app_id/website/config/:web_id" element={<WebConfig />} />
      </Route>
    </Routes> 
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
