// Dashboard.js
import React from 'react';
import Sidebar from '../../Components/Util/Sidebar/Sidebar';
import Qa_list from './List';
import './Organization_application.css'; 

const Index = () => {
  return ( 
    <>
    <div className="dashboard-container">
      <Sidebar />
      <div>
        <Qa_list />
      </div>
    </div>
    </>
  );
};

export default Index;
