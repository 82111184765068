import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import './careerJobPosting.css';
import axios from "axios";

const CareerJobPosting = () => {
    const paramsDt = useParams();
    const BASE_URL = process.env.REACT_APP_CAREER_API_URL;
    const [carrerData, setCarrerData] = useState([]);

    useEffect(() => {
        getCarrerById(paramsDt.ado_id);
    }, [paramsDt.ado_id]);

    const getCarrerById = async (ado_id) => {
        try {
            let response = await axios.get(BASE_URL + "job_posting/"+ado_id);
            setCarrerData(response.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    return(
        <section className="career-job-posting">
            <div className="container">
            <h3 className="mb-3">{carrerData.title} <span className="ado-id">#{carrerData.ado_id}</span></h3>
                <p className="post-date">{carrerData.created_at}</p>
                <a className="apply-btn btn btn-color" href={`/careers/`+carrerData.ado_id+`/apply`}>Apply Now</a>
                {carrerData.type && <p className="mb-0"><b>Job Type: </b> {carrerData.type}</p>}
                {carrerData.experience && <p className="mb-0"><b>Experience: </b> {carrerData.experience}</p>}
                {carrerData.location && <p className="mb-0"><b>Location: </b> {carrerData.location}</p>}
                {carrerData.employment_type && <p className="mb-0"><b>Employment Type: </b> {carrerData.employment_type}</p>}
                {carrerData?.description && (
                    <>
                        <p className="mb-0"><b>Job Description: </b></p>
                        <p dangerouslySetInnerHTML={{ __html: carrerData?.description }}></p>
                    </>
                )}
                {/* <a className="apply-btn btn btn-color bottom" href={`/careers/`+carrerData.ado_id+`/apply`}>Apply Now</a> */}
            </div>
        </section>
    )
}

export default CareerJobPosting;