// Dashboard.js
import React from 'react';
import Sidebar from '../../Components/Util/Sidebar/Sidebar';
import Website_list from './Website';
import './website.css'; 

const Index = () => {
  return ( 
    <>
    <div className="dashboard-container">
      <Sidebar />
      <div className='layout-sec'>
        <Website_list />
      </div>
    </div>
    </>
  );
};

export default Index;
