import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { ORG_DATA, SAVE_FILER_CAT } from '../../Components/Util/Constant';
import { Table, Container } from 'react-bootstrap';
import Sidebar from '../../Components/Util/Sidebar/Sidebar';

const WebsiteList = () => {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [websiteList, setWebsiteList] = useState({});
  const web_data = useParams();

  const fetchWebsiteList = async () => {
      let token = localStorage.getItem("token");
      let app_id = web_data?.app_id;
      let org_data = JSON.parse(localStorage.getItem(ORG_DATA));
      if (org_data?.orgId && app_id && token) {
        await axios
          .get(`${BASE_URL}/appser/api/apps/`+ app_id +`/configs`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              OrgId: org_data?.orgId
            },
        })
        .then((response) => {
            return response.data;
        })
        .then((data) => {
            setWebsiteList(data.data);
        })
        .catch((err) => {
            console.error(err);
            if (err?.response?.status === 401) {
              localStorage.clear();
              navigate('/login');
            }
        });
      }
  };

  useEffect(() => {
      fetchWebsiteList();
  }, []);

  const clickWebName = (web_id) => {
    let wb_dt = {"web_id": web_id}
    localStorage.setItem(SAVE_FILER_CAT, JSON.stringify(wb_dt));
  }

  return (
    <>
      <div className="dashboard-container">
        <Sidebar submenuData={websiteList}/>
        <div className='layout-sec'>
          <Container id="web_list" className="web-list mt-30">
            <div className="head-sec">
                <h3 className="inner-page-title">Website</h3>
                <Link className="add-btn" to={"/" + web_data?.app_id + "/website/create"}>Add Website</Link>
            </div>
            <div className="web-list-table">
            <Table striped className="table-number">
                    <thead className="table-head">
                        <tr>
                            <th className="table-col-name">Name</th>
                            <th className="table-col-url">URL </th>
                            <th className="table-col-address">Ip Address</th>
                            <th className="table-col-action">Action</th>
                        </tr>
                    </thead>
                </Table>
                <div className="table-body-container">
                    <Table striped className="table-number">
                        <tbody>
                            {websiteList?.length > 0 && websiteList?.map((webData, indx) => {
                                return (
                                    <tr className="table-row" key={webData?.id}>
                                        <td className="table-col-name"><Link to={`/product-category/list`} onClick={() => clickWebName(webData?.id)}>{webData.data?.name}</Link></td>
                                        <td className="table-col-url">{webData.data?.domain}</td>
                                        <td className="table-col-address">{webData.data?.ip_address}</td>
                                        <td className="table-col-action"><Link to={`/${web_data?.app_id}/website/config/${webData?.id}`}><i className='fa fa-cog'></i></Link></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                {!websiteList?.length > 0  && <p className="no-data">No website found</p>}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default WebsiteList;
